import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, getUserData } from "./utils/utils";
import { createCase } from "./services/myPnpServices";
import Loader from "./components/common/Loder";
import Modal, { modalVarientEnum } from "./components/common/GenericModal";
import { Button, Icon } from "@abb/abb-common-ux-react";
import { MUIButton, MUISelect, MUITextField } from "@abb/abb-arcadia-ui-react";

const MyCaseEntry: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [caseType, setCaseType] = useState("");
  const [description, setDescription] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [message, setMessage] = useState("");
  const [caseNo, setCaseNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [fileError, setFileError] = useState("");
  const maxSize = 3; // Max file size in MB
  const [fileName, setFileName] = useState<string | null>(null);
  const [subjectError, setSubjectError] = useState("");
  const [caseTypeError, setCaseTypeError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [acceptPolicyError, setAcceptPolicyError] = useState<string>("");
  const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);
  const allowedFormats = [
    ".txt",
    ".doc",
    ".docx",
    ".pdf",
    ".ppt",
    ".pptx",
    ".xls",
    ".xlsx",
  ];
  const { showNotification } = useNotification();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      const fileSizeMB = file.size / 1024 / 1024; // Convert bytes to MB

      if (fileExtension && allowedFormats.includes(`.${fileExtension}`)) {
        if (fileSizeMB > maxSize) {
          setFileError(
            `The file size exceeds the limit of ${maxSize} MB. Please select a smaller file.`
          );
          setFile(null);
          setFileName(null);
        } else if (file.size === 0) {
          setFileError(
            "Empty file uploaded! Please select a file with content."
          );
          setFile(null);
          setFileName(null);
        } else {
          setFile(file);
          setFileName(file.name);
          setFileError("");
        }
      } else {
        setFileError("Invalid file type. Please select a valid file format.");
        setFile(null);
        setFileName(null);
      }
    }
  };
  const handleFileRemove = () => {
    setFileName(null);
    (document.getElementById("file") as HTMLInputElement).value = "";
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");
    const email = urlParams.get("email");
    const home = urlParams.get("home");

    if (!user && email) {
      window.location.href = "/home";
      return;
    }

    if (user && email) {
      setUserName(user);
      setEmail(email);
    } else {
      const userData = getUserData();
      const storedUserName = `${userData.given_name} ${userData.family_name}`;
      const storedEmail = userData.email;

      if (storedUserName && storedEmail) {
        setUserName(storedUserName);
        setEmail(storedEmail);
      } else {
        const { userName: passedUserName, email: passedEmail } =
          location.state || {
            userName: "",
            email: "",
          };
        if (passedUserName && passedEmail) {
          setUserName(passedUserName);
          setEmail(passedEmail);
        }
      }
    }
    if (home) {
      document.getElementById("home")!.setAttribute("href", home);
    }
  }, [location.state]);

  const validateNum = () => {
    const regex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
    if (regex.test(phoneNumber) || phoneNumber === "") {
      setValidationMessage("");
    } else {
      setValidationMessage("*Invalid phone number format!");
    }
  };

  const validateInput = (
    input: string,
    setter: React.Dispatch<React.SetStateAction<string>>,
    setError: React.Dispatch<React.SetStateAction<string>>,
    fieldName: string
  ) => {
    if (/^\s*$/.test(input)) {
      setError(`Please fill in the ${fieldName}.`);
      setter("");
    } else {
      setError("");
      setter(input);
    }
  };

  const openModal = (message: string, caseNo: string) => {
    setLoading(false);
    setMessage(message);
    setCaseNo(caseNo);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    sessionStorage.setItem(
      "text_msg",
      "Case is created successfully! Please close the window"
    );
    window.location.reload();
  };
  const handlePolicyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptPolicy(event.target.checked);
    if (event.target.checked) {
      setAcceptPolicyError(""); // Clear error message when checkbox is checked
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (validationMessage) {
      alert("Please input a valid phone number");
      return;
    }
    let isValid = true;

    if (!acceptPolicy) {
      setAcceptPolicyError("You must agree to the Privacy Policy.");
      isValid = false;
    } else {
      setAcceptPolicyError("");
    }

    if (!subject) {
      setSubjectError("Please fill in the Subject.");
      isValid = false;
    }

    if (!caseType) {
      setCaseTypeError("Please select a Case Type.");
      isValid = false;
    }

    if (!description) {
      setDescriptionError("Please provide a Short Description.");
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    setLoading(true);

    const formdata = new FormData();
    formdata.append("BusinessArea", "IA");
    formdata.append("CaseApiQueueName", "IA/Case Management/Support");
    formdata.append("CaseType", caseType);
    formdata.append("CaseSubType", "General");
    formdata.append("Country", "United States");
    formdata.append("Description", description);
    formdata.append("Division", "IAPI");
    formdata.append("Email", email);
    formdata.append("PhoneNumber", phoneNumber);
    formdata.append("Severity", "Normal");
    formdata.append("Subject", subject);
    formdata.append("SupportType", "Post Sales");
    formdata.append("UserName", userName);
    formdata.append("CasePriority", "Low");
    if (file) {
      formdata.append("Files", file);
    }

    try {
      const response = await createCase(formdata);

      const caseNo = response?.data[0]?.caseNumber;
      const message = response?.data[0]?.message.replaceAll("_", " ");

      openModal(message, caseNo);
      showNotification([
        buildNotification("Case is created successfully!", "success"),
      ]);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 429) {
        const message =
          "You have already submitted a ticket. Please try again after one hour";
        showNotification([buildNotification(message, "alarm")]);
      } else if (error?.response?.status === 413) {
        const message = "Request is too large";
        showNotification([buildNotification(message, "alarm")]);
      } else {
        const message = "Error creating case. Please try again later";
        showNotification([buildNotification(message, "alarm")]);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="case-creation-form">
      <form name="scp_salesforce" id="scp_salesforce" onSubmit={handleSubmit}>
        <h1 className="session-control-heading">My Case Entry</h1>
        {/* <div>
              <a id="home" href="abb.com"></a>
            </div> */}
        {/* <div className="help-tip">
              <p>Please close this window once the case is created!</p>
            </div> */}
        {/* <p style={{ color: "rgb(87, 163, 0)" }} id="text_msg">
              {sessionStorage.getItem("text_msg")}
            </p> */}
        <div className="form-container">
          <h4 className="contact-us-heading">Contact Us</h4>
          <p className="test-msg">
            Please complete the form below to have a support case automatically
            created in our case tracking system. <br />
            If you have a time sensitive or urgent issue, we recommend that you
            contact us at <strong>1-800 HELP 365 (1 800 435 7365)</strong>{" "}
            Option 5 for Pulp & Paper support as phone requests are treated as
            highest priority.
          </p>

          <div className="form-wrapper">
            <div className="form-block">
              <div className="label">
                <span className="asterisk">*</span>Subject
              </div>
              <MUITextField
                onChange={(evt: any) =>
                  validateInput(evt, setSubject, setSubjectError, "Subject")
                }
                value={subject}
                onKeyDown={() => {}}
                placeholder={"Subject"}
                minWidth={200}
                size={32}
              />
              {subjectError && <p className="error-message">{subjectError}</p>}
            </div>
            <br />
            <div className="form-block-select">
              <div className="label">
                <span className="asterisk">*</span>Case Type
              </div>
              <MUISelect
                dropdownLOV={[
                  { id: "", name: "Select your option" },
                  { id: "Technical Support", name: "Technical Support" },
                  { id: "Field Service", name: "On-Site Support" },
                ]}
                onChange={(val: string[]) =>
                  validateInput(
                    val[0],
                    setCaseType,
                    setCaseTypeError,
                    "Case Type"
                  )
                }
                selectedItem={caseType}
                size={32}
                minWidth={240}
                multiSelect={false}
              />
              {caseTypeError && (
                <p className="error-message">{caseTypeError}</p>
              )}
            </div>
            <br />
            <div>
              <div className="label">
                <span className="asterisk">*</span>Short Description
              </div>
              <textarea
                className="dropdown"
                name="Description"
                id="Description"
                rows={4}
                value={description}
                onChange={(e) =>
                  validateInput(
                    e.target.value,
                    setDescription,
                    setDescriptionError,
                    "Short Description"
                  )
                }
                required
              ></textarea>
              {descriptionError && (
                <p className="error-message">{descriptionError}</p>
              )}
            </div>
            <br />
            <div className="row_c">
              <div className="subheading2">Contact Details</div>
            </div>
            <div className="field-container">
              <div className="label">User Name :</div>
              <MUITextField
                variant="read-only"
                onChange={(evt: any) =>
                  validateInput(evt, setSubject, setSubjectError, "Subject")
                }
                value={userName}
                onKeyDown={() => {}}
                placeholder={"UserName"}
                minWidth={200}
                size={32}
              />
            </div>
            <div className="field-container">
              <div className="label">Email :</div>
              <MUITextField
                variant="read-only"
                onChange={(evt: any) =>
                  validateInput(evt, setSubject, setSubjectError, "Subject")
                }
                value={email}
                onKeyDown={() => {}}
                placeholder={"Email"}
                minWidth={200}
                size={32}
              />
            </div>
            <div className="field-container">
              <div className="label">Phone Number :</div>
              <MUITextField
                variant="read-only"
                onChange={(evt: any) =>
                  validateInput(evt, setSubject, setSubjectError, "Subject")
                }
                value={phoneNumber}
                onKeyDown={() => {}}
                placeholder={""}
                minWidth={200}
                size={32}
              />
              {validationMessage && (
                <p className="error-message">{validationMessage}</p>
              )}
            </div>
            <div className="file-upload-container">
              <label htmlFor="file" className="button-attachfile">
                ATTACH FILE
                <input
                  type="file"
                  id="file"
                  name="file"
                  accept={allowedFormats.join(",")}
                  onChange={handleFileChange}
                  className="file-input"
                  tabIndex={7}
                />
              </label>
              <p className="file-format-message">
                Please select a file in one of the supported formats: .txt,
                .doc, .docx, .pdf, .ppt, .pptx, .xls, or .xlsx
              </p>
            </div>
            {fileName && (
              <div className="selected-file">
                <span className="label">Selected file: </span> {fileName}
                <button
                  type="button"
                  onClick={handleFileRemove}
                  className="remove-file-button"
                >
                  ✕
                </button>
              </div>
            )}
            {fileError && (
              <div className="error-message">
                <span className="file-message">{fileError}</span>
              </div>
            )}
            <br />

            <div className="row_c">
              <div className="row">
                <div className="contents3">
                  <a
                    className="privacy-policy-link"
                    href="https://new.abb.com/privacy-policy"
                    target="_blank"
                  >
                    <span className="asterisk">*</span> I agree to the Privacy
                    Policy
                  </a>
                  <input
                    className="tick"
                    type="checkbox"
                    name="accept-policy"
                    id="accept-policy"
                    checked={acceptPolicy}
                    onChange={handlePolicyChange}
                    required
                  ></input>
                  {acceptPolicyError && (
                    <p className="error-message">{acceptPolicyError}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="button-section">
              <button className="button-cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button className="button-submit" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      {loading && <div className="loader">Loading...</div>}{" "}
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          actionButton="Close"
          varient={modalVarientEnum.DEFAULT}
          actionButtonRequired={false}
        >
          <div className="d-flex-content-center">
            <div className="text-center mt-20">
              <div className="icon">
                <Icon name="abb/check-mark" sizeClass="large" color="green" />
              </div>
              <div className="reject-message mt-20">
                <p>
                  <strong>{message}</strong>
                </p>
                <p>
                  <strong>{caseNo}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="modal-action-btn ">
            <Button
              sizeClass="small"
              shape={"pill"}
              className="secondary-btn mt-20"
              text={"Close"}
              onClick={() => {
                window.location.reload();
                setIsModalOpen(false);
              }}
            />
          </div>
        </Modal>
      )}
      {loading && <Loader showOverlay />}
    </div>
  );
};

export default MyCaseEntry;
