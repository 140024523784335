// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.import-export-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.import-export-container .intro-header {
  padding-left: 20px;
}
.import-export-container .content-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 20px;
}
.import-export-container .content-wrapper .roles-heading {
  font-size: 14px;
  font-weight: 500;
  background-color: #f5f5f5;
  margin: 2px;
  padding: 4px 10px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.import-export-container .content-wrapper .form-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 12px;
  gap: 20px;
  width: 400px;
}
.import-export-container .content-wrapper .form-wrapper .input-wrapper .input-label {
  font-size: 12px !important;
}
.import-export-container .content-wrapper .form-wrapper .field-container {
  display: flex;
}
.import-export-container .content-wrapper .form-wrapper .field-container button {
  margin-top: 22px;
  margin-left: 18px;
}
.import-export-container .content-wrapper .button-container {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-left: auto;
  margin-bottom: 14px;
  margin-right: 14px;
  gap: 10px;
}
.import-export-container .content-wrapper .button-container button {
  width: 90px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/import_export.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,sBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AAAE;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,sBAAA;EACA,mBAAA;EACA,cAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,4BAAA;EACA,2BAAA;AAGN;AADI;EACE,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,SAAA;EACA,YAAA;AAGN;AADQ;EACE,0BAAA;AAGV;AAAM;EACE,aAAA;AAER;AAAQ;EACE,gBAAA;EACA,iBAAA;AAEV;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;AAAN;AAEM;EACE,WAAA;AAAR","sourcesContent":[".import-export-container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  .intro-header {\n    padding-left: 20px;\n  }\n  .content-wrapper {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    background-color: #fff;\n    border-radius: 10px;\n    margin: 0 20px;\n    .roles-heading {\n      font-size: 14px;\n      font-weight: 500;\n      background-color: #f5f5f5;\n      margin: 2px;\n      padding: 4px 10px;\n      border-top-right-radius: 8px;\n      border-top-left-radius: 8px;\n    }\n    .form-wrapper {\n      display: flex;\n      flex-direction: column;\n      margin: 20px 12px;\n      gap: 20px;\n      width: 400px;\n      .input-wrapper {\n        .input-label {\n          font-size: 12px !important;\n        }\n      }\n      .field-container {\n        display: flex;\n\n        button {\n          margin-top: 22px;\n          margin-left: 18px;\n        }\n      }\n    }\n    .button-container {\n      display: flex;\n      flex-direction: row;\n      margin-top: auto;\n      margin-left: auto;\n      margin-bottom: 14px;\n      margin-right: 14px;\n      gap: 10px;\n\n      button {\n        width: 90px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
