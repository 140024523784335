import React, { useState, useEffect, useMemo, useContext } from "react";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { TimePicker } from "antd";
import * as ABB from "@abb/abb-common-ux-react";
import "@abb/abb-common-ux-react/styles.css";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { Space } from "antd";
import { Select } from "antd";
import { getAllApplications } from "./services/myPnpServices";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, buildPaginationData, getUserData, getDropdownValue } from "./utils/utils";
import { useUserContext } from "./app_context/UserContext";
import { useNavigate } from "react-router-dom";
import { MyPnPDataGrid } from "pnp-components-library";
import moment from "moment";
import dayjs from 'dayjs';
import {
  MUIDatePicker,
  MUISelect,
  MUITextField,
  Pagination,
} from "@abb/abb-arcadia-ui-react";
import { getApplicationDetailsAPI, getAllUsersAPI, getAuditLogsAPI } from "./services/myPnpServices"
import CustomDropdown from "./components/common/Dropdown";
import { getUserDataFromToken } from "./utils/buildnotify";
const { Option } = Select;
interface activityLog {
  id: number;
  application: string;
  user: string;
  lastupdated: string;
  users_log: string;
}

const ActivityLogs = () => {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const { email, user_realm_roles, name } = getUserData()
  let getRole = user_realm_roles.filter((item: any) => {
    return item === "SuperAdmin"
  })
  const [selectedStartDate, setSelectedStartDate] = useState<any>(getCurrentDate())
  const [selectedEndDate, setSelectedEndDate] = useState<any>(getCurrentDate())
  const [selectedStartTime, setSelectedStartTime] = useState<any>(dayjs("00:00:00", "HH:mm:ss").format("HH:mm:ss"))
  const [selectedEndTime, setSelectedEndTime] = useState<any>(dayjs("23:59:59", "HH:mm:ss").format("HH:mm:ss"))
  const [applicationList, setApplicationList] = useState<any>([])
  const [selectedAppInfo, setSelectedAppInfo] = useState<any>([])
  const [selectedValueSingle, setSelectedValueSingle] = useState<number[]>([]);
  const [selectedLogType, setSelectedLogType] = useState<any>()
  const [componentName, setComponentName] = useState<string>("")
  const [exportDialogOpen, setExportDialogOpen] = useState(true);
  const [settingDialogOpen, setSettingDialogOpen] = useState(true);
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(10);
  const [selectedUserInfo, setSelectedUserInfo] = useState<any>([
    {
      id: email,
      name: name
    }
  ])
  const [selectedUserList, setSelectedUserList] = useState<any>([])
  const [selectedAppList, setSelectedAppList] = useState<any>()
  const [userList, setUserList] = useState([])
  const [auditLogsData, setAuditLogsData] = useState<any>()
  const { showNotification } = useNotification();
  const { userData } = useUserContext();
  const imagesData = React.useContext(ImagesContext);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const { images }: any = imagesData;
  const navigate = useNavigate();
  const storedTheme = localStorage.getItem("userTheme");
  const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);
  const [showPermission, setShowPermission] = useState(false); 
  const user_data = getUserDataFromToken() || null;
  useEffect(() => {
    const { realm_access } = user_data || {};
    if (realm_access.roles.includes("SuperAdmin")) {
      setShowPermission(true);
    }
  }, []);

  useEffect(() => {
    const date = new Date()
    const currentDate = moment(date).startOf('day');
    setSelectedUserList([])
    const payload = {
      application: [],
      component: null,
      subComponent: null,
      feature: null,
      userDetails: [email],
      logType: null,
      startDate: getStartDate() !== null || getStartDate() !== "" ? getStartDate() : currentDate.toISOString(),
      endDate: getEndDate() !== null || getEndDate() !== "" ? getEndDate() : currentDate.toISOString()
    }
    getDefaultAuditLogs(payload)
    getApplicationList();
  }, [])

  const getDefaultAuditLogs = async (payload: any) => {
    const response = await getAuditLogsAPI(payload);
    setAuditLogsData(response?.data)
  }
  const getApplicationList = async () => {
    const res = await getApplicationDetailsAPI();
    setApplicationList(res)
  }
  const logTypes = [
    {
      id: "0",
      name: "Information"
    },
    {
      id: "1",
      name: "Error"
    }
  ]

  useEffect(() => {
    const access_token = userData?.data?.access_token;
    if (access_token) {
      callGetAllApplications();
      if(showPermission){
        getAllUserList();
      }
    }
  }, []);

  useEffect(() => {
    const columns = buildColumns(auditLogsData);
    setColumnDefs(columns);
  }, [auditLogsData]);

  const buildColumns = (data: any): (ColDef | ColGroupDef)[] => {
    const baseColumns: (ColDef | ColGroupDef)[] = [];
    const columnGroupName = "Activity Logs";
    baseColumns.push({
      headerName: columnGroupName,
      children: [
        {
          headerName: "Application",
          field: "application",
          filter: true,
        },
        {
          headerName: "User Name",
          field: "createdBy",
          filter: true,
        },
        {
          headerName: "Component",
          field: "component",
          filter: true,
        },
        {
          headerName: "Sub Component",
          field: "subcomponent",
          filter: true,
        },
        {
          headerName: "Description",
          field: "description",
          filter: true,
        },
        {
          headerName: "Feature",
          field: "feature",
          filter: true,
        },
        {
          headerName: "Last Updated",
          field: "updateOn",
          cellRenderer: (data: any) => {
            return moment(data?.data?.updateOn).format("DD-MMM-YYYY HH:mm:ss")
          },
          filter: true,
        },
        {
          headerName: "Log Type",
          field: "logType",
          filter: true,
        },
        {
          headerName: "Action",
          field: "action",
          filter: true,
        }
      ],
    } as ColGroupDef);

    return baseColumns;
  };

  const getAllUserList = async () => {
    const result = await getAllUsersAPI();
    setUserList(result)
  }

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      }
    }
  };

  const handleSave = () => {
    showNotification([buildNotification("Saved successfully!", "success")]);
  };

  const onExportClick = () => {
    setExportDialogOpen(true);
    if (settingDialogOpen) setSettingDialogOpen(false);
  };

  const onSettingsClick = () => {
    setSettingDialogOpen(true);
    if (exportDialogOpen) setExportDialogOpen(false);
  };

  const onPageNumClick = (evt: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleRowsPerPage = (evt: any) => {
    const { value } = evt?.target || {};
    setPageSize(value);
  };
  const buildTableData = useMemo(() => {
    let currentUsersData = buildPaginationData(
      currentPage + 1,
      pageSize,
      auditLogsData
    );
    return currentUsersData ?? [];
  }, [currentPage, auditLogsData, pageSize]);

  const handleStartTimeChange = (time: any) => {
    setSelectedStartTime(time ? time.format("HH:mm:ss") : null);
  }

  const handleEndTimeChange = (time: any) => {
    setSelectedEndTime(time ? time.format("HH:mm:ss") : null);
  }

  const getStartDate = () => {
    let startDate;
    if (selectedStartDate !== "" && selectedStartTime !== "") {
      startDate = `${selectedStartDate}T${selectedStartTime}`
    } else if (selectedStartDate !== "" && selectedStartTime === "") {
      startDate = moment(selectedStartDate).format()
    } else if (selectedStartDate === "" && selectedStartTime !== "") {
      startDate = `${moment().format("YYYY-MM-DD")}T${selectedStartTime}`
    } else if (selectedStartDate === "" && selectedStartTime === "") {
      startDate = null
    }
    return startDate
  }

  const getEndDate = () => {
    let endDate;
    if (selectedEndDate !== "" && selectedEndTime !== "") {
      endDate = `${selectedEndDate}T${selectedEndTime}`
    } else if (selectedEndDate !== "" && selectedEndTime === "") {
      endDate = moment(selectedEndDate).format()
    } else if (selectedEndDate === "" && selectedEndTime !== "") {
      endDate = `${moment().format("YYYY-MM-DD")}T${selectedEndTime}`
    } else if (selectedEndDate === "" && selectedEndTime === "") {
      endDate = null
    }
    return endDate
  }

  const onApplyClick = async () => {
    const selectedUserEmails = selectedUserList?.map((obj: any) => obj.value);
    const selectedApps = selectedAppList?.map((obj: any) => obj.label === "Service Pro" ? "ServicePro" : obj.label)
    const date = new Date()
    const currentDate = moment(date).startOf('day');
    let payload: any = {
      application: selectedApps !== undefined && selectedApps.length > 0 ? selectedApps : [],
      component: componentName !== "" ? componentName : null,
      subComponent: null,
      feature: null,
      userDetails: selectedUserList.length > 0 ? selectedUserEmails : selectedUserInfo.length === 0 ? [] : [selectedUserInfo[0].id],
      logType: selectedLogType === undefined ? null : selectedLogType[0],
      startDate: getStartDate() !== null || getStartDate() !== "" ? getStartDate() : currentDate.toISOString(),
      endDate: getEndDate() !== null || getEndDate() !== "" ? getEndDate() : currentDate.toISOString()
    }
    const response = await getAuditLogsAPI(payload);
    setAuditLogsData(response?.data)
  }

  const onDropdownChange = (val: any) => {
    const updatedData = val.filter((c: any) => c.id)
    setSelectedUserList(val)
    if (val.length === 0) {
      setSelectedUserInfo([])
    }
  }

  const onAppDropdownChange = (val: any) => {
    const updatedData = val.filter((c: any) => c.id)
    setSelectedAppList(val)
    setSelectedAppInfo((prev: any) => [...prev, ...updatedData])
  }

  const handleLogType = (val: any) => {
    setSelectedLogType(val)
  }

  return (
    <div className="activity-log-body-container">
      {!permitted && showPermissionModal && (
        <div className="modal-overlay">
          <div className="modal red-border">
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={() => {
                  navigate("/home");
                }}
              >
                &times;
              </button>
              <p>
                You don’t have the required permissions to access Platform
                Administration Application. Please contact the administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      {permitted === true && (
        <div>
          <h1>Activity Logs</h1>
          <div className="activity-log-filtering-container">
            <div className="filter-text-buttons-group-conatiner">
              <div className="filter-button">
                <div className="filter-label">
                  {general_text_data.filter_by} :
                </div>
              </div>
              <div className="btn-export-btn-settings">
                <Space>
                  <ABB.WithDialog>
                    <ABB.Button
                      type="ghost"
                      text="Settings"
                      shape="pill"
                      className="btn-ghost-type"
                      icon="abb/settings"
                      sizeClass="small"
                      style={{ border: "2px solid #36F" }}
                      onClick={() => onSettingsClick()}
                      disabled={true}
                    />
                    <ABB.Dialog
                      showCloseButton={true}
                      closeOnEscape={true}
                      closeOnLostFocus={true}
                      hideBackground={false}
                      className="popup"
                      isOpen={settingDialogOpen}
                    >
                      <div className="settings-dialog">
                        <h3 className="settings-heading">
                          {general_text_data.settings}
                        </h3>
                        <div>
                          <hr className="horizontal-line" />
                          <h5 className="retention-period-txt">
                            {general_text_data.retention_period}
                          </h5>
                          <p className="length-message">
                            {general_text_data.lenght_message}
                          </p>
                        </div>
                        <ABB.ToggleButtonGroup
                          selected={selectedValueSingle}
                          onChange={(v) => setSelectedValueSingle(v)}
                          sizeClass="small"
                          multiselect={false}
                        >
                          <ABB.ToggleButton text="1 Month" />
                          <ABB.ToggleButton text="3 Months" />
                          <ABB.ToggleButton text="6 Months" />
                          <ABB.ToggleButton text="1 Year" />
                          <ABB.ToggleButton text="2 Years" />
                          <ABB.ToggleButton text="3 Years" />
                          <ABB.ToggleButton text="5 Years" />
                          <ABB.ToggleButton text="7 Years" />
                        </ABB.ToggleButtonGroup>
                        <div className="export-cancel-save-btn">
                          <button
                            className="btn-cancel"
                            type="reset"
                            onClick={() => setSettingDialogOpen(false)}
                          >
                            {general_text_data.cancel}
                          </button>
                          <button
                            onClick={handleSave}
                            className="btn-save"
                            type="submit"
                          >
                            {general_text_data.save}
                          </button>
                        </div>
                      </div>
                    </ABB.Dialog>
                  </ABB.WithDialog>
                </Space>
              </div>
            </div>
            <div className="application-row-conatiner">
              <div className="application-dropdown">
                <label className="label" htmlFor="application">
                  Application
                </label>{" "}
                <br />
                <CustomDropdown
                  enableSearch={false}
                  key={selectedAppInfo}
                  placeholder="Select App"
                  items={applicationList?.map(
                    (c: { name: string; appRegId: string }) => ({
                      name: c.name,
                      id: c.appRegId,
                    })
                  )}
                  onChange={(val: any) => onAppDropdownChange?.(val)}
                  multiSelect={true}
                  selectedItems={selectedAppInfo.filter((c: any) => c.id).map((item: any) => {
                    return item.id
                  })}
                />
              </div>
              <div>
                <label className="label">Start Date</label>
                <br />
                <MUIDatePicker
                  description=""
                  onChange={(val: any) => {
                    setSelectedStartDate(val ? val.startOf('day').format("YYYY-MM-DD") : null)
                  }}
                  size={32}
                  minWidth={280}
                  defaultValue={selectedStartDate}
                />
              </div>
              <div>
                <label className="label">Start Time</label>
                <br />
                <TimePicker
                  className="common-input"
                  style={{ height: "32px", width: "240px" }}
                  onChange={handleStartTimeChange}
                  format="HH:mm:ss"
                  defaultValue={dayjs("00:00:00", "HH:mm:ss")}
                />
              </div>
              <div>
                <div>
                  <label className="label">Log Type:</label>
                  <div className="application-dropdown">
                    <MUISelect
                      placeholder="Select Log type"
                      dropdownLOV={
                        logTypes?.map((e: any) => {
                          return {
                            id: e.name,
                            name: e.name
                          }
                        })
                      }
                      onChange={(val: string[]) => handleLogType(val)}
                      selectedItems={selectedLogType}
                      size={32}
                      minWidth={240}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="user-row-conatiner">
              <div>
                <label className="label">User Name</label>
                <div className="application-dropdown">
                    <CustomDropdown
                      enableSearch={true}
                      key={selectedUserInfo}
                      placeholder="Select User"
                      items={ showPermission
                        ? userList?.map(
                        (c: { firstName: string; lastName: string; email: string }) => ({
                          name: `${c.firstName} ${c.lastName}`,
                          id: c.email,
                        })
                      )
                      : [
                        {
                          name: user_data.name,
                          id: user_data.email,
                        },
                      ]}
                      onChange={(val: any) => onDropdownChange?.(val)}
                      multiSelect={true}
                      selectedItems={
                        showPermission
                        ? selectedUserInfo.filter((c: any) => c.id).map((item: any) => {
                        return item.id
                      })
                      : [user_data.email]}
                    />
                  </div>
              </div>
              <div>
                <label className="label">End Date</label>
                <br />
                <MUIDatePicker
                  description=""
                  onChange={(val: any) => {
                    setSelectedEndDate(val ? val.startOf('day').format("YYYY-MM-DD") : null)
                  }}
                  selectedItem={[]}
                  size={32}
                  minWidth={280}
                  defaultValue={selectedEndDate}
                />
              </div>
              <div>
                <label className="label">End Time</label>
                <br />
                <TimePicker
                  className="common-input"
                  style={{ height: "32px", width: "240px" }}
                  onChange={handleEndTimeChange}
                  format="HH:mm:ss"
                  defaultValue={dayjs("23:59:59", "HH:mm:ss")}
                />
              </div>
              <div>
                <label className="label">Component</label><br />
                <MUITextField
                  onChange={(val: any) => {
                    setComponentName(val)
                  }}
                  onKeyDown={() => { }}
                  placeholder={"Component Name"}
                  minWidth={240}
                  size={32}
                />
              </div>
              <div className="button-apply">
                <ABB.Button
                  className="btn-apply"
                  type="primary-blue"
                  text={"Apply"}
                  shape="pill"
                  onClick={onApplyClick}
                />
              </div>
            </div>
          </div>
          <div className="tabulator">
            <MyPnPDataGrid
              columnDefs={columnDefs}
              columns={[]}
              rowData={buildTableData || []}
              pagination={false}
              isExportRequired={true}
              exportBtnPosition={"start"}
              exportLabel={
                <>
                  <ABB.Button
                    style={{ border: "2px solid #36F" }}
                    icon="abb/export"
                    type="ghost"
                    text="Export"
                    shape="pill"
                    sizeClass="small"
                    className="btn-ghost-type"
                    disabled={buildTableData?.length === 0}
                  />
                </>
              }
            />
            <Pagination
              count={auditLogsData?.length || 0}
              handleChangePage={onPageNumClick}
              handleChangeRowsPerPage={handleRowsPerPage}
              page={currentPage}
              rowsPerPage={pageSize}
              showNumberButtons={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ActivityLogs;
