import React, { useState, useEffect } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { getAllApplications } from "./services/myPnpServices";
import { useNavigate } from "react-router-dom";
import { MUISelect } from "@abb/abb-arcadia-ui-react";
import { getUserDataFromToken } from "./utils/buildnotify";

const ImportExportApplication = () => {
  const user_data = getUserDataFromToken() || null;
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false); // New state for modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    callGetAllApplications();
  }, []);

  useEffect(() => {
    const { realm_access } = user_data || {};
    if (!realm_access.roles.includes("SuperAdmin")) {
      setPermitted(false);
    }
  }, []);

  useEffect(() => {
    if (!permitted) {
      setShowPermissionModal(true);
    }
  }, [permitted]);

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  return (
    <>
      {!permitted &&
        showPermissionModal && ( // Conditional rendering of the modal
          <div className="modal-overlay">
            <div className="modal red-border">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => {
                    navigate("/home");
                  }}
                >
                  &times;
                </button>
                <p>
                  You don’t have the required permissions to access Platform
                  Administration Application. Please contact the administrator.
                </p>
              </div>
            </div>
          </div>
        )}
      {permitted === true && (
        <div className="import-export-container">
          <div className="intro-header">
            <h1>Import/Export Application</h1>
          </div>
          <div className="content-wrapper">
            <div className="roles-heading">Import/Export Application</div>
            <div className="form-wrapper">
              <div className="field-container">
                <MUISelect
                  label="Export Application"
                  description="Applications definitions to the file"
                  placeholder="Placeholder"
                  dropdownLOV={[]}
                  onChange={(val: string[]) => {}}
                  selectedItem={[]}
                  size={32}
                  minWidth={280}
                  multiSelect
                />
                <ABB.Button
                  onClick={() => {}}
                  style={{ border: "2px solid #36F" }}
                  icon="abb/export"
                  type="ghost"
                  text="Export"
                  shape="pill"
                  sizeClass="small"
                  className="btn-ghost-type"
                />
              </div>
              <div className="field-container">
                <MUISelect
                  label="Import Application"
                  description="Applications definitions from export file"
                  placeholder="Browse"
                  dropdownLOV={[]}
                  onChange={(val: string[]) => {}}
                  selectedItem={[]}
                  size={32}
                  minWidth={280}
                  multiSelect
                />
                <ABB.Button
                  onClick={() => {}}
                  style={{ border: "2px solid #36F" }}
                  icon="abb/import"
                  type="ghost"
                  text="Import"
                  shape="pill"
                  sizeClass="small"
                  className="btn-ghost-type"
                />
              </div>
            </div>
            <div className="button-container">
              <ABB.Button
                text={"Cancel"}
                shape="pill"
                className="cancel-button"
                //onClick={() => showNewPageCancel()}
              />
              <ABB.Button
                className="btn-save"
                type="primary-blue"
                text={"Save"}
                shape="pill"
                // onClick={handleSave}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImportExportApplication;
