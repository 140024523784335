import React, { useState, useEffect, useMemo } from "react";
import { DatePicker, TimePicker } from "antd";
import * as ABB from "@abb/abb-common-ux-react";
import "@abb/abb-common-ux-react/styles.css";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { Space } from "antd";
import { Select } from "antd";
import { ReactTabulator } from "react-tabulator";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import { getAllApplications } from "./services/myPnpServices";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, buildPaginationData } from "./utils/utils";
import { useUserContext } from "./app_context/UserContext";
import { useNavigate } from "react-router-dom";
import { MyPnPDataGrid } from "pnp-components-library";
import {
  MUIDatePicker,
  MUISelect,
  MUITextField,
  Pagination,
} from "@abb/abb-arcadia-ui-react";

const { Option } = Select;
interface activityLog {
  id: number;
  application: string;
  user: string;
  lastupdated: string;
  users_log: string;
}

const ActivityLogs = () => {
  const [selecteduser, setSelecteduser] = useState([]);
  const handleChangeUser = (values: any) => {
    setSelecteduser(values);
  };
  const [selectedValueSingle, setSelectedValueSingle] = useState<number[]>([]);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [selectedApplication, setSelectedApplication] = useState([]);
  const [exportDialogOpen, setExportDialogOpen] = useState(true);
  const [settingDialogOpen, setSettingDialogOpen] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState<activityLog[]>([]);
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [currentPage, setCurrentPage] = useState<any>(0);
  const [pageSize, setPageSize] = useState<any>(10);
  const { showNotification } = useNotification();
  const { userData } = useUserContext();
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;
  const navigate = useNavigate();

  useEffect(() => {
    const access_token = userData?.data?.access_token;
    if (access_token) {
      callGetAllApplications();
    }
  }, []);

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      }
    }
  };

  const handleApplicationChange = (value: any) => {
    setSelectedApplication(value);
  };
  const columns = [
    {
      title: "Application",
      field: "application",
    },
    {
      title: "User",
      field: "user",
    },
    {
      title: "Start Date",
      field: "startDate",
    },
    {
      title: "End Date",
      field: "endDate",
    },
    {
      title: "Time logs",
      field: "users_log",
    },
  ];
  const data = [
    {
      id: 1,
      application: "Service Pro",
      user: "ganpat.k@in.abb.com",
      startDate: "02/25/2024 02:30:10",
      endDate: "02/26/2024 04:30:10",
      lastupdated: "25/01/2024~26/01/2024",
      users_log: "24hrs",
    },
    {
      id: 2,
      application: "My QCS",
      user: "ajeya.b@in.abb.com",
      startDate: "03/10/2024 02:30:10",
      endDate: "03/11/2024 05:30:10",
      lastupdated: "05/05/2024~06/05/2024",
      users_log: "24hrs",
    },
  ];
  const handleSave = () => {
    showNotification([buildNotification("Saved successfully!", "success")]);
  };

  const onExportClick = () => {
    setExportDialogOpen(true);
    if (settingDialogOpen) setSettingDialogOpen(false);
  };

  const onSettingsClick = () => {
    setSettingDialogOpen(true);
    if (exportDialogOpen) setExportDialogOpen(false);
  };

  const onSearchValueChanged = (searchValue: string) => {
    setSearchValue(searchValue);
    onFilter(searchValue);
  };
  const onApplyClick = () => {
    let filtered = data.filter(
      (e) =>
        e.application.toLowerCase() === selectedApplication[0] &&
        e.user.toLowerCase() === selecteduser[0]
    );
    setTableData(filtered);
  };
  const onFilter = (search: string) => {
    let filtered = data.filter(
      (ele) =>
        ele.user.toLowerCase().includes(search.toLocaleLowerCase()) ||
        ele.application.toLowerCase().includes(search.toLocaleLowerCase())
    );
    setTableData(filtered);
  };
  const onPageNumClick = (evt: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const handleRowsPerPage = (evt: any) => {
    const { value } = evt?.target || {};
    setPageSize(value);
  };
  const buildTableData = useMemo(() => {
    let currentUsersData = buildPaginationData(
      currentPage + 1,
      pageSize,
      tableData
    );
    return currentUsersData ?? [];
  }, [currentPage, tableData, pageSize]);

  return (
    <div className="activity-log-body-container">
      {!permitted && showPermissionModal && (
        <div className="modal-overlay">
          <div className="modal red-border">
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={() => {
                  navigate("/home");
                }}
              >
                &times;
              </button>
              <p>
                You don’t have the required permissions to access Platform
                Administration Application. Please contact the administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      {permitted === true && (
        <div>
          <h1>Activity Logs</h1>
          <div className="activity-log-filtering-container">
            <div className="filter-text-buttons-group-conatiner">
              <div className="filter-button">
                <div className="filter-label">
                  {general_text_data.filter_by} :
                </div>
              </div>
              <div className="btn-export-btn-settings">
                <Space>
                  <ABB.WithDialog>
                    <ABB.Button
                      onClick={() => onExportClick()}
                      style={{ border: "2px solid #36F" }}
                      icon="abb/export"
                      type="ghost"
                      text="Export"
                      shape="pill"
                      sizeClass="small"
                      className="btn-ghost-type"
                    />
                    <ABB.Dialog
                      showCloseButton={true}
                      closeOnEscape={true}
                      closeOnLostFocus={true}
                      hideBackground={false}
                      isOpen={exportDialogOpen}
                    >
                      <div className="export-dialog">
                        <h2 className="activity-log-export-txt">
                          {general_text_data.activity_log_export}
                        </h2>
                        <hr />
                        <p className="">{general_text_data.export_message1}</p>
                        <br />
                        <p>{general_text_data.export_message2}</p>
                      </div>
                      <div className="export-cancel-save-btn">
                        <button
                          className="btn-cancel"
                          type="reset"
                          onClick={() => setExportDialogOpen(false)}
                        >
                          {general_text_data.cancel}
                        </button>
                        <button
                          onClick={handleSave}
                          className="btn-save"
                          type="submit"
                        >
                          {general_text_data.save}
                        </button>
                      </div>
                    </ABB.Dialog>
                  </ABB.WithDialog>
                  <ABB.WithDialog>
                    <ABB.Button
                      type="ghost"
                      text="Settings"
                      shape="pill"
                      className="btn-ghost-type"
                      icon="abb/settings"
                      sizeClass="small"
                      style={{ border: "2px solid #36F" }}
                      onClick={() => onSettingsClick()}
                    />
                    <ABB.Dialog
                      showCloseButton={true}
                      closeOnEscape={true}
                      closeOnLostFocus={true}
                      hideBackground={false}
                      className="popup"
                      isOpen={settingDialogOpen}
                    >
                      <div className="settings-dialog">
                        <h3 className="settings-heading">
                          {general_text_data.settings}
                        </h3>
                        <div>
                          <hr className="horizontal-line" />
                          <h5 className="retention-period-txt">
                            {general_text_data.retention_period}
                          </h5>
                          <p className="length-message">
                            {general_text_data.lenght_message}
                          </p>
                        </div>
                        <ABB.ToggleButtonGroup
                          selected={selectedValueSingle}
                          onChange={(v) => setSelectedValueSingle(v)}
                          sizeClass="small"
                          multiselect={false}
                        >
                          <ABB.ToggleButton text="1 Month" />
                          <ABB.ToggleButton text="3 Months" />
                          <ABB.ToggleButton text="6 Months" />
                          <ABB.ToggleButton text="1 Year" />
                          <ABB.ToggleButton text="2 Years" />
                          <ABB.ToggleButton text="3 Years" />
                          <ABB.ToggleButton text="5 Years" />
                          <ABB.ToggleButton text="7 Years" />
                        </ABB.ToggleButtonGroup>
                        <div className="export-cancel-save-btn">
                          <button
                            className="btn-cancel"
                            type="reset"
                            onClick={() => setSettingDialogOpen(false)}
                          >
                            {general_text_data.cancel}
                          </button>
                          <button
                            onClick={handleSave}
                            className="btn-save"
                            type="submit"
                          >
                            {general_text_data.save}
                          </button>
                        </div>
                      </div>
                    </ABB.Dialog>
                  </ABB.WithDialog>
                </Space>
              </div>
            </div>
            <div className="application-row-conatiner">
              <div className="application-dropdown">
                <label className="label" htmlFor="application">
                  Application:
                </label>{" "}
                <br />
                <MUISelect
                  dropdownLOV={[{ id: 1, name: "Service Pro" }]}
                  onChange={(val: string[]) => handleApplicationChange(val[0])}
                  selectedItem={[selectedApplication]}
                  size={32}
                  minWidth={280}
                  multiSelect
                />
              </div>
              <div>
                <label className="label">Start Date:</label>
                <br />
                <MUIDatePicker
                  description=""
                  onChange={(val: string[]) => {}}
                  selectedItem={[]}
                  size={32}
                  minWidth={280}
                />
              </div>
              <div>
                <label className="label">Start Time:</label>
                <br />
                <TimePicker
                  className="common-input"
                  style={{ height: "32px", width: "240px" }}
                  // onChange={(value: any) => setStartTime(value)}
                />
              </div>
            </div>
            <div className="user-row-conatiner">
              <div>
                <label className="label">User:</label>
                <div className="application-dropdown">
                  <MUISelect
                    dropdownLOV={[
                      {
                        id: "ganpat.k@in.abb.com",
                        name: "ganpat.k@in.abb.com",
                      },
                      { id: "ajeya.b@in.abb.com", name: "ajeya.b@in.abb.com" },
                    ]}
                    onChange={(val: string[]) => handleChangeUser(val[0])}
                    selectedItem={[selecteduser]}
                    size={32}
                    minWidth={280}
                    multiSelect
                  />
                </div>
              </div>
              <div>
                <label className="label">End Date:</label>
                <br />
                <MUIDatePicker
                  description=""
                  onChange={(val: string[]) => {}}
                  selectedItem={[]}
                  size={32}
                  minWidth={280}
                />
              </div>
              <div>
                <label className="label">End Time:</label>
                <br />
                <TimePicker
                  className="common-input"
                  style={{ height: "32px", width: "240px" }}
                  // onChange={(value: any) => setendTime(value)}
                />
              </div>
              <div className="button-apply">
                <ABB.Button
                  className="btn-apply"
                  type="primary-blue"
                  text={"Apply"}
                  shape="pill"
                  onClick={onApplyClick}
                />
              </div>
            </div>
          </div>
          <div className="activity-search">
            <MUITextField
              onChange={onSearchValueChanged}
              onKeyDown={() => {}}
              value={searchValue}
              placeholder={"Search for Application"}
              size={32}
            />
          </div>
          <div className="tabulator">
            <MyPnPDataGrid columns={columns} rowData={buildTableData || []} />
            <Pagination
              count={tableData.length || 0}
              handleChangePage={onPageNumClick}
              handleChangeRowsPerPage={handleRowsPerPage}
              page={currentPage}
              rowsPerPage={pageSize}
              showPrevNext={false}
              showNumberButtons={false}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default ActivityLogs;
