import { useState, useEffect, useContext } from "react";
import { Space } from "antd";
import { Link } from "react-router-dom";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { SEARCH_TRIGGER_LENGTH } from "../src/components/constants";
import BannerImage from "./banner_image";
import searchIcon from "../src/assets/images/icon_search_box.svg";
import { getAllApplicationType } from "./services/myPnpServices";

import { MUITextField } from "@abb/abb-arcadia-ui-react";

const Home = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [allApplicationTypes, setAllApplicationTypes] = useState<any[]>([]);
  const [filteredApplications, setFilteredApplications] = useState<any[]>([]);
  const appGeneralTextData = useContext(AppGeneralTextContext);
  const imagesData = useContext(ImagesContext);
  const { general_text_data } = appGeneralTextData;
  const { images } = imagesData;

  useEffect(() => {
    //userPreference();
    callAllApplicationTypes();
  }, []);

  useEffect(() => {
    if (allApplicationTypes.length > 0) {
      filterAndSortApplication("", "asc");
    }
  }, [allApplicationTypes]);

  const callAllApplicationTypes = async () => {
    const iconMapping: { [key: number]: string } = {
      1: "icon_services.svg",
      2: "icon_core_extension.svg",
      3: "icon_platform_administration.svg",
    };

    try {
      let response = await getAllApplicationType();
      if (response.length > 0) {
        const updatedData = response.map((item: any) => {
          if (item.applicationTypeName === 'Service Apps') {
            return {
              ...item,
              icon: iconMapping[item.applicationTypeId] || "icon_services.svg",
              description:
                "Efficient and cost-effective service platform.",
            };
          }
          if (item.applicationTypeName === 'Platform Administration') {
            return {
              ...item,
              icon: iconMapping[item.applicationTypeId] || "icon_platform_administration.svg",
              description:
                "User accounts and permissions management.",
            };
          }
          if (item.applicationTypeName === 'Extensions') {
            return {
              ...item,
              icon: iconMapping[item.applicationTypeId] || "icon_core_extension.svg",
              description:
                "Boost your Paper Machine’s quality and efficiency.",
            };
          }
        });
        

        updatedData.push({
          applicationTypeId: 4,
          applicationTypeName: "My Apps",
          isActive: true,
          createdBy: "pradeep",
          createdDate: null,
          lastUpdatedBy: null,
          lastUpdatedDate: null,
          icon: "icon_my_apps.svg",
          description: "Reliable and convenient service management platform.",
        });
       
        setAllApplicationTypes(updatedData);
      }
    } catch (err) {}
  };

  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
    const searchInput = value.length > SEARCH_TRIGGER_LENGTH ? value : "";
    filterAndSortApplication(searchInput, "asc");
  };

  const toggleSortOrder = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
    filterAndSortApplication(searchTerm, sortOrder);
  };

  const filterAndSortApplication = (searchValue: string, sortValue: string) => {
    const filteredAndSortedApplications = allApplicationTypes
      .filter((app) =>
        app.applicationTypeName
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      )
      .sort((a, b) => {
        const comparison = a.applicationTypeName.localeCompare(
          b.applicationTypeName
        );
        return sortValue === "asc" ? comparison : -comparison;
      });
    setFilteredApplications(
      filteredAndSortedApplications.length > 0
        ? filteredAndSortedApplications
        : allApplicationTypes
    );
  };

  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>Home</h1>
      </div>
      <div className="intro-banner-container">
        <BannerImage />
      </div>
      <div className="intro-content">{general_text_data.description_home}</div>
      <div className="services-content">
        <div className="misc-actions-container mr-20">
          <div className="cta-btns">
            <Space>
              <div className="input-group">
                <MUITextField
                  onChange={handleSearchChange}
                  onKeyDown={() => {}}
                  placeholder={"Search for Application"}
                  size={32}
                />
                {/* <img
                  className="search-img"
                  style={{ position: "absolute", right: 5 }}
                  src={searchIcon}
                  width={17}
                  height={32}
                /> */}
              </div>
              <div className="icon-sort" onClick={toggleSortOrder}>
                <img
                  className="icon-sort"
                  src={
                    sortOrder === "asc"
                      ? `${images.sort_icon_asc}`
                      : `${images.sort_icon_dsc}`
                  }
                  alt="sort"
                  title={
                    sortOrder === "asc"
                      ? "Sort in ascending order"
                      : "Sort in descending order"
                  }
                />
              </div>
            </Space>
          </div>
        </div>
        <div className=" grid-container">
          {filteredApplications.map((data) => {
            const url = data.applicationTypeName
              .replace(/\s+/g, "_")
              .toLowerCase();
            const imgSrc = `../assets/images/${data.icon}`;
            return (
              <div className="blocks-container col-3">
                <Link
                  to={`/${url}`}
                  reloadDocument={true}
                  key={data.applicationTypeId}
                >
                  <div className="blocks" key={data.id}>
                    <div className="app-info">
                      <div className="image-container">
                        <div>
                          <img src={imgSrc} alt={data.applicationTypeName} />
                        </div>
                      </div>
                      <div className="app-name">
                        <h4>{data.applicationTypeName}</h4>
                      </div>
                    </div>
                    <div className="box-content">
                      <p
                        title={
                          data?.description.length > 95
                            ? data.description
                            : undefined
                        }
                      >
                        {data?.description.length > 95
                          ? data.description.slice(0, 95) + " ..."
                          : data.description}
                      </p>
                    </div>
                    <div className="app-link">
                      {general_text_data.go_to_app}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
