import React, { useEffect, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Formik, Form, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { MyPnPDataGrid } from "pnp-components-library";
import {
  getAllApplicationConfigurations,
  getAllApplications,
  getAllRoles,
  inviteNewUser,
  requestApplication,
} from "./services/myPnpServices";
import { buildNotification } from "./utils/utils";
import { AllRoles } from "./assignrole";
import { useNotification } from "./app_context/NotificationContext";
import * as Yup from "yup";
import { EXTENSION_APP } from "./components/constants";
import { getUserDataFromToken } from "./utils/buildnotify";
import { MUIButton, MUISelect, MUITextField } from "@abb/abb-arcadia-ui-react";
import CustomDropdown from "./components/common/Dropdown";

const InviteNewUser = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [selectedRole, setSelectedRole] = useState<any>([]);
  const [selectedApplicatn, setSelectedApplicatn] = useState<any>([]);
  const [allAppications, setAllAppications] = useState<any>([]);
  const [roleTable, setRoleTable] = useState<any>();
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const { showNotification } = useNotification();
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);

  const navigate = useNavigate();
  const state = useLocation().state;

  const storedUserData = getUserDataFromToken();
  const userData = state.type === "user_self_request" ? storedUserData : null;

  const buildTableData = () => {
    return (
      roleTable &&
      roleTable?.map((data: any) => {
        return {
          key: "1",
          applicationName: data?.applicationName,
          Role: data.role,
        };
      })
    );
  };

  const columns = [
    {
      field: "applicationName",
      flex: 1,
    },
    {
      field: "Role",
      flex: 1,
    },
  ];

  useEffect(() => {
    callGetAllApplications();
  }, []);

  useEffect(() => {
    if (state.type === "user_self_request") {
      fetchAllAppsConfig();
    }
  }, [state.type]);

  useEffect(() => {
    if (clientIds.length > 0 && state.type !== "user_self_request") {
      callGetUserRoles(selectedCategory?.label);
    }
  }, [selectedCategory, clientIds]);

  const callGetAllApplications = async () => {
    try {
      let result = await getAllApplications();
      if (result.length > 0) {
        let applications: any[] = result;
        let clientIdsArray: string[];

        if (state.type === "user_self_request") {
          clientIdsArray = applications
            .filter((app) => app.applicationCategoryId === EXTENSION_APP)
            .map((app) => app.client_id);
        } else {
          clientIdsArray = applications?.map((app) => app.client_id);
        }
        setClientIds(clientIdsArray);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        showNotification([
          buildNotification("Failed to fetch Applications", "alarm"),
        ]);
      }
    }
  };

  const callGetUserRoles = async (category: string) => {
    try {
      const response = await getAllRoles(clientIds);
      if (response.data?.length > 0) {
        const filteredData = response.data?.filter((item: any) => {
          if (category === "Extensions") {
            return item?.name?.startsWith("Extensions");
          } else if (category === "Service Apps") {
            return item?.name?.startsWith("Service Apps");
          }
          return false;
        });

        const updatedData = filteredData.map((role: any) => {
          const displayName = role?.name.includes(":")
            ? role.name.split(":")[1]
            : role.name;

          return {
            ...role,
            displayName,
          };
        });

        setAllRoles(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAllAppsConfig = async () => {
    try {
      const serviceResp = await getAllApplicationConfigurations(2);
      if (serviceResp.length > 0) {
        setAllAppications(serviceResp);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const findRoleData = (value: any, allRoles: any) => {
    return (
      allRoles &&
      allRoles?.find(
        (role: any) =>
          role.id === value[0]?.value || role.name === value[0]?.label
      )
    );
  };
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().trim().required("First Name is required"),
    lastName: Yup.string().trim().required("Last Name is required"),
    email: Yup.string()
      .trim()
      .email("Invalid email format")
      .matches(
        /^[^\s@]+@([a-zA-Z]{2}\.)?abb\.com$/,
        "Please enter a valid ABB email id"
      )
      .required("Email is required"),
  });

  const handleApplication = (value: any) => {
    if (value && value.length > 0) {
      setSelectedApplicatn(value[0]);
    }
  };

  const handleChangeRole = (value: any) => {
    if (!value || !value[0]) {
      return;
    }
  
    const selectedValue = value[0];
    const isRoleSelected = selectedRole?.some(
      (role: any) => role?.value === selectedValue?.value
    );
  
    if (isRoleSelected) {
      // Remove the role from selectedRole
      const updatedSelectedRole = selectedRole?.filter(
        (role: any) => role?.value !== selectedValue?.value
      );
      setSelectedRole(updatedSelectedRole);
  
      // Remove the role from roleTable
      setRoleTable((prevRoleTable: any) => {
        return prevRoleTable?.filter(
          (role: any) => role.id !== selectedValue.value
        );
      });
    } else {
      let role = {
        value: selectedValue?.value,
        label: selectedValue?.label,
      };
      setSelectedRole([...selectedRole, role]);
  
      const roleData = findRoleData(value, allRoles);
      if (roleData) {
        const transformedData = [
          {
            ...roleData,
            applicationName: roleData.applicationName || "",
            role: roleData?.name.includes(":")
              ? roleData?.name.split(":")[1].trim()
              : roleData?.name,
          },
        ];
  
        assignToRoleTable(transformedData);
      }
    }
  };
  

  const assignToRoleTable = (transformedData: any) => {
    setRoleTable((prevRoleTable: any) => {
      if (!Array.isArray(prevRoleTable)) {
        prevRoleTable = [];
      }

      const filteredData = transformedData?.filter((newRole: any) => {
        return !prevRoleTable?.some((existingRole: any) => {
          return (
            existingRole.id === newRole.id && existingRole.role === newRole.role
          );
        });
      });

      return [...prevRoleTable, ...filteredData];
    });
  };
  const handleCategoryValue = (value: any) => {
    if (value && value.length > 0) {
      setSelectedCategory(value[0]);
    }
  };

  const handleCancelpage = () => {
    if (state.type === "user_self_request") {
      navigate("/contact_abb");
    } else {
      navigate("/platform_administration/user_mapping/assign_role");
    }
  };
  const onRequestApplication = async (values: any) => {
    try {
      const payload = {
        firstname: values?.firstName,
        lastname: values?.lastName,
        username: values?.email,
        appregid: selectedApplicatn,
        comment: values?.comments,
      };
      const response = await requestApplication(payload);
      showNotification([buildNotification(response.data, "success")]);
      navigate("/contact_abb");
    } catch (err: any) {
      if (err?.response?.data) {
        showNotification([buildNotification(err?.response?.data, "alarm")]);
      } else {
        showNotification([
          buildNotification("Unable to raise request", "alarm"),
        ]);
      }
    }
  };

  const handleSave = async (values: any) => {
    const roles = roleTable?.map((data: any) => ({
      id: data.id,
      name: data.name,
      description: data.description,
      composite: data.composite,
      clientRole: data.clientRole,
      containerId: data.container_id,
    }));

    const payload = {
      firstName: values?.firstName,
      lastName: values?.lastName,
      email: values?.email,
      username: values?.email,
      enabled: true,
      emailVerified: true,
      roles: roles,
    };
    try {
      const response = await inviteNewUser(payload);
      if (response) {
        showNotification([
          buildNotification("User onboarded to MyPnP successfully", "success"),
        ]);
        if (state.type === "user_self_request") {
          navigate("/contact_abb");
        } else {
          navigate("/platform_administration/user_mapping/assign_role");
        }
      }
    } catch (err: any) {
      if (err?.response?.data?.includes("User exists")) {
        showNotification([
          buildNotification("User exists with same username", "alarm"),
        ]);
      } else {
        showNotification([
          buildNotification("Unable to onboard user to MyPnP", "alarm"),
        ]);
      }
    }
  };

  return (
    <div className="role-deinition-screen">
      <h1>{general_text_data.invite_new_user}</h1>
      <Formik
        initialValues={{
          firstName: userData ? userData.given_name : "",
          lastName: userData ? userData.family_name : "",
          email: userData ? userData.email : "",
          comments: "",
        }}
        onSubmit={(values, { setSubmitting }) => {
          state.type === "user_self_request"
            ? onRequestApplication(values)
            : handleSave(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ values, handleChange, handleBlur, isSubmitting }) => (
          <Form>
            <div className="new-role-container">
              <div className="new-role-title-container">
                <h3 className="new-role-title">
                  {general_text_data.invite_new_user}
                </h3>
              </div>
              <div className="grid-container">
                <div className="col-3">
                  <div className="new-role-content-container">
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        {general_text_data.first_name}{" "}
                        <span className="asterisk">*</span>
                      </div>
                      <MUITextField
                        onChange={(val: any) => {
                          const evt = {
                            target: {
                              value: val,
                              name: "firstName",
                            },
                          };
                          handleChange(evt);
                        }}
                        onBlur={handleBlur}
                        value={values.firstName}
                        onKeyDown={() => {}}
                        placeholder={"Enter first name"}
                        minWidth={240}
                        size={32}
                      />
                      <ErrorMessage
                        name="firstName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        {general_text_data.last_name}
                        <span className="asterisk">*</span>
                      </div>
                      <MUITextField
                        onChange={(val: any) => {
                          const evt = {
                            target: {
                              value: val,
                              name: "lastName",
                            },
                          };
                          handleChange(evt);
                        }}
                        onBlur={handleBlur}
                        value={values.lastName}
                        onKeyDown={() => {}}
                        placeholder={"Enter last name"}
                        minWidth={240}
                        size={32}
                        isDisabled={state.type === "user_self_request"}
                      />
                      <ErrorMessage
                        name="lastName"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="new-role-content-section">
                      <div className="new-role-label">
                        {general_text_data.email_or_user_name}{" "}
                        <span className="asterisk">*</span>
                      </div>
                      <MUITextField
                        onChange={(val: any) => {
                          const evt = {
                            target: {
                              value: val,
                              name: "email",
                            },
                          };
                          handleChange(evt);
                        }}
                        onBlur={handleBlur}
                        value={values.email}
                        onKeyDown={() => {}}
                        placeholder={"Enter email"}
                        minWidth={240}
                        size={32}
                        isDisabled={state.type === "user_self_request"}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    {state.type === "user_self_request" ? (
                      <>
                        <div className="new-role-content-section">
                          <div className="new-role-label">
                            {general_text_data.application_name}{" "}
                            <span className="asterisk">*</span>
                          </div>
                          <MUISelect
                            dropdownLOV={
                              allAppications?.map((e: any) => {
                                return {
                                  id: e.appRegId,
                                  name: e.applicationName,
                                };
                              }) || []
                            }
                            onChange={(val: string[]) => handleApplication(val)}
                            selectedItem={selectedApplicatn}
                            size={32}
                            minWidth={240}
                            multiSelect={false}
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="new-role-content-section">
                          <div className="new-role-label">Select Category</div>

                          <CustomDropdown
                            key={selectedCategory?.value}
                            placeholder="select"
                            items={state.application?.map((e: any) => {
                              return {
                                id: e.applicationCategoryId,
                                name: e.client_Name,
                              };
                            })}
                            onChange={(val: any) => {
                              handleCategoryValue(val);
                            }}
                            multiSelect={false}
                            selectedItems={[selectedCategory?.value]}
                          />
                        </div>
                        <div className="new-role-content-section">
                          <div className="new-role-label">
                            {general_text_data.application_role}{" "}
                            <span className="asterisk">*</span>
                          </div>
                          <CustomDropdown
                            key={selectedRole?.value}
                            placeholder="select"
                            items={allRoles?.map((e: any) => {
                              return {
                                id: e.id,
                                name: e.displayName,
                              };
                            })}
                            onChange={(val: any) => {
                              handleChangeRole(val);
                            }}
                            multiSelect={false}
                            selectedItems={[selectedRole?.value]}
                          />
                        </div>
                      </>
                    )}
                    {state.type === "user_self_request" ? (
                      <div className="new-role-content-section">
                        <div className="new-role-label">
                          {general_text_data.comments}
                        </div>
                        <MUITextField
                          onChange={(val: any) => {
                            const evt = {
                              target: {
                                value: val,
                                name: "comments",
                              },
                            };
                            handleChange(evt);
                          }}
                          onBlur={handleBlur}
                          value={values.comments}
                          onKeyDown={() => {}}
                          placeholder={"Add your comments"}
                          minWidth={240}
                          size={32}
                        />
                        <ErrorMessage
                          name="comments"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    ) : null}

                    <div className="new-role-content-section">
                      {roleTable && (
                        <MyPnPDataGrid
                          columns={columns}
                          rowData={buildTableData()}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-role-definition-input-group-button">
                <MUIButton
                  label={general_text_data.cancel}
                  onClickButton={handleCancelpage}
                  size={32}
                  variant="secondary"
                />

                <button
                  type="submit"
                  className="btn-submit"
                  disabled={
                    values.firstName?.trim() === "" ||
                    values.lastName?.trim() === "" ||
                    values.email?.trim() === "" ||
                    (selectedRole?.length === 0 &&
                      selectedApplicatn?.length === 0) ||
                    isSubmitting
                  }
                >
                  {general_text_data.save}
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default InviteNewUser;
