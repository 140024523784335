import React, { useEffect, useState } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { AppGeneralTextContext } from "./app_context/app_context";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import {
  deleteAssignedRoleToUser,
  getAllApplications,
  getAllRoles,
  getUserDetailsWithAppltnRole,
} from "./services/myPnpServices";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification } from "./utils/utils";
import { useNavigate } from "react-router-dom";
import { MyPnPDataGrid } from "pnp-components-library";
import { MUIButton, MUITextField, Pagination } from "@abb/abb-arcadia-ui-react";
export interface TableData {
  updated: boolean;
  id: string;
  role: string;
  email: string;
  region: string;
  roles: any;
}
interface AllApplications {
  client_Name: string;
  client_id: string;
  application: string;
}
interface RolesType {
  description: string;
  id: string;
  name: string;
  container_id: string;
}
interface DeleteUser {
  user: string;
  role: any;
  rowData: any;
  updatedRole: any;
}
interface AllRoles {
  clientRole: boolean;
  composite: boolean;
  description: string;
  id: string;
  name: string;
  usersInRole: any;
  usersInRoleCount: number;
  container_id: string;
}

const UserMappingAndRole = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [currentPage, setCurrentPage] = useState(0);
  const [permitted, setPermitted] = useState(true);
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);

  const [allApplications, setAllApplications] = useState<AllApplications[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [allRolesData, setAllRolesData] = useState<TableData[]>([]);
  const [tableDataFiltered, setTableDataFiltered] = useState<TableData[]>([]);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const [deleteUser, setDeleteUser] = useState<DeleteUser>();
  const [clientId, setClientId] = useState<any>();
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const [pageSize, setPageSize] = useState<any>(10);
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    setSearchValue("");
    callGetAllApplications();
    callGetUserDetails();
  }, []);

  useEffect(() => {
    if (clientId?.length > 0) {
      callGetUserRoles();
    }
  }, [clientId]);

  const refreshCells = (userId: string, roles: any) => {
    tableDataFiltered?.map((row) => {
      if (row.id === userId) row.roles = roles;
    });
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
    onSearchIconClick(value);
  };

  const callGetAllApplications = async () => {
    try {
      const resp = await getAllApplications();
      if (resp.length > 0) {
        let applications: AllApplications[] = [];
        applications = resp;
        let client_id = applications?.map((data) => data.client_id);
        setClientId(client_id);
        applications.unshift(allApplications[0]);
        setAllApplications(applications);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const callGetUserDetails = async () => {
    try {
      let payload = { start: "", max: "", search: "" };
      let response = await getUserDetailsWithAppltnRole(payload);
      if (response.data.length > 0) {
        const updatedData = response?.data.map((user: any) => ({
          ...user,
          roles: user.roles.map((role: any) => ({
            ...role,
            name: role.name.split(":")[1],
          })),
        }));

        setAllRolesData(response.data);
        setTableData(updatedData);
        setTableDataFiltered(updatedData);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const DeleteRole = async (userId: string, role: RolesType) => {
    const findUser = allRolesData?.filter((data) => data.id === userId);
    const findRole = findUser[0]?.roles.find(
      (data: any) => data.id === role.id
    );
    let payload = [
      {
        id: role.id,
        name: findRole.name,
        description: role.description,
        composite: false,
        clientRole: true,
        containerId: deleteUser?.role.client_id,
      },
    ];
    try {
      let response = await deleteAssignedRoleToUser(
        clientId[0],
        userId,
        deleteUser?.rowData.email,
        payload
      );
      if (response) {
        setdeleteDialogOpen(false);
        showNotification([buildNotification(response, "success")]);
        tableDataFiltered?.forEach((row) => {
          if (row.id === deleteUser?.user) row.roles = deleteUser?.updatedRole;
          row.updated = true;
        });
      }
    } catch (err: any) {
      setdeleteDialogOpen(false);
      if (err) {
        err.response.data ? (
          showNotification([buildNotification(err.response.data, "alarm")])
        ) : (
          <></>
        );
      }
    }
  };

  const onSearchIconClick = (value: any) => {
    if (value.length > 0) {
      let filtered = tableData?.filter((ele) =>
        ele.email.toLowerCase().includes(value.toLowerCase())
      );
      setTableDataFiltered(filtered);
    } else {
      setTableDataFiltered(tableData);
    }
  };

  const onAlphaFilter = (search: string) => {
    let filtered = tableData?.filter((ele) =>
      ele.email.toLowerCase().startsWith(search.toLowerCase())
    );
    setTableDataFiltered(filtered);
  };

  const callGetUserRoles = async () => {
    try {
      const response = await getAllRoles(clientId);
      if (response) {
        setAllRoles(response.data);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const RolesFormatter = (params: any) => {
    const roles = params.value;
    const tags = roles.map((role: any, index: any) => (
      <div key={index} className="role-tag">
        <div className="single-role-tag">{role.name}</div>
        <button
          className="close-icon"
          onClick={() => {
            let rowData = params.data;
            let updatedRole = rowData.roles.filter(
              (e: any) => e.name !== role.name
            );
            setDeleteUser({
              user: params.data.id,
              role: role,
              rowData: rowData,
              updatedRole: updatedRole,
            });
            setdeleteDialogOpen(true);
          }}
        >
          x
        </button>
      </div>
    ));
    return <div className="roles-tags">{tags}</div>;
  };

  const columns = [
    {
      title: "User",
      field: "email",
      flex: 1,
    },
    {
      title: "Role",
      field: "roles",
      flex: 2,
      resizable: false,
      autoHeight: true,
      cellRenderer: RolesFormatter,
    },
    {
      title: "Region",
      field: "region",
      flex: 1,
    },
  ];

  const getSelectedAlpha = (ind: number) => {
    return String.fromCharCode(64 + ind).toString();
  };

  const onAlphabetChange = (selected: number[]) => {
    getSelectedAlpha(selected[0]);
    onAlphaFilter(getSelectedAlpha(selected[0]));
  };

  const handleALL_Click = () => {
    setTableDataFiltered(tableData);
  };

  const handleDelete_Cancel = () => {
    setdeleteDialogOpen(false);
    let prevRole: any[] = [];
    tableData.map((data) => {
      if (data.id === deleteUser?.user) {
        data.roles.map((role: any) => {
          prevRole.push(role);
        });
      }
    });

    refreshCells(deleteUser?.user || "", prevRole);
  };

  const onPageNumClick = (evt: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginationConfig = {
    pageNumber: currentPage,
    pageSize: pageSize,
    totalRecords: tableDataFiltered?.length ?? 0,
    onPageClick: onPageNumClick,
  };

  const buildPaginationData = (pageNum: any, data: any) => {
    const indexOfLastItem = pageNum * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentData = data
      ? data.slice(indexOfFirstItem, indexOfLastItem)
      : [];

    return currentData;
  };

  const buildTableData = () => {
    let currentUsersData =
      tableDataFiltered &&
      buildPaginationData(currentPage + 1, tableDataFiltered);
    return currentUsersData;
  };

  const handleOnClear = () => {
    setSearchValue("");
  };

  const handleRowsPerPage = (evt: any) => {
    const { value } = evt?.target || {};
    setPageSize(value);
  };

  return (
    <>
      {!permitted && showPermissionModal && (
        <div className="modal-overlay">
          <div className="modal red-border">
            <div className="modal-content">
              <button
                className="modal-close"
                onClick={() => {
                  navigate("/home");
                }}
              >
                &times;
              </button>
              <p>
                You don’t have the required permissions to access Platform
                Administration Application. Please contact the administrator.
              </p>
            </div>
          </div>
        </div>
      )}
      {permitted === true && (
        <div className="user-mapping">
          <h1 className="user-mapping-heading">
            {general_text_data.user_mapping_and_role}
          </h1>

          <div className="user-mapping-content">
            <div className="user-mapping-filters">
              <div className="search-assign-role-section">
                <div className="search-site-section">
                  <MUITextField
                    onChange={onSearchValueChange}
                    value={searchValue}
                    onKeyDown={() => {}}
                    placeholder={"Search for User"}
                    minWidth={200}
                    size={32}
                  />
                </div>
                <div className="assign-role-button">
                  <MUIButton
                    label="Assign Role"
                    onClickButton={() =>
                      navigate(
                        "/platform_administration/user_mapping/assign_role"
                      )
                    }
                    size={32}
                    variant="secondary"
                    iconName="icon-abb_plus"
                  />
                </div>
              </div>
            </div>
            <div className="user-mapping-alpha-filter">
              <ABB.ToggleButtonGroup
                onChange={(selected) => onAlphabetChange(selected)}
              >
                <ABB.ToggleButton
                  key={"ALL"}
                  text={"ALL"}
                  onClick={handleALL_Click}
                />
                {alphabet.map((letter) => (
                  <ABB.ToggleButton key={letter} text={letter} />
                ))}
              </ABB.ToggleButtonGroup>
            </div>
            <div className="user-mapping-table">
              <div className="roles-heading">Roles</div>
              <MyPnPDataGrid
                columns={columns}
                rowData={buildTableData()}
                domLayout="autoHeight"
              />

              {paginationConfig && (
                <Pagination
                  count={paginationConfig.totalRecords}
                  handleChangePage={paginationConfig!.onPageClick}
                  handleChangeRowsPerPage={handleRowsPerPage}
                  page={paginationConfig.pageNumber}
                  rowsPerPage={paginationConfig.pageSize}
                  showPrevNext={false}
                  showNumberButtons={false}
                />
              )}
            </div>
          </div>
        </div>
      )}
      {deleteDialogOpen && (
        <ABB.Dialog
          showCloseButton={true}
          closeOnEscape={true}
          closeOnLostFocus={true}
          hideBackground={false}
          isOpen={deleteDialogOpen}
          onClose={() => setdeleteDialogOpen(false)}
        >
          <div className="delete-role-dialog">
            <p className="info-icon">&#x1F6C8;</p>
            <h2 className="delete-heading">Delete</h2>
            <p className="delete-confirm-msg">
              Are you sure you want to delete?
            </p>
          </div>
          <div className="btn-cancel-yes-delete-it-btn">
            <ABB.Button
              sizeClass="small"
              type="discreet-blue"
              text="Cancel"
              shape="pill"
              onClick={() => handleDelete_Cancel()}
            />
            <ABB.Button
              sizeClass="small"
              type="primary-blue"
              text="Yes,Delete it!"
              shape="pill"
              onClick={() => {
                DeleteRole(deleteUser?.user || "", deleteUser?.role);
              }}
            />
          </div>
        </ABB.Dialog>
      )}
    </>
  );
};
export default UserMappingAndRole;
