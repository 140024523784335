import { jwtDecode } from "jwt-decode";

export const getDropdownValue = (label: string, value: string) => {
  return [
    {
      label: label,
      value: value,
    },
  ];
};

export const buildQueryParams = (params: { [key: string]: any }) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const buildNotification = (
  message: string,
  type: "success" | "info" | "warn" | "alarm"
) => {
  return {
    id: Math.random() > 0.7,
    type: "banner",
    severity: type,
    timeout: 3000,
    text: message,
  };
};

// Cliendside Pagination
export const buildPaginationData = (pageNum = 1, pageSize = 5, data: any) => {
  const indexOfLastItem = pageNum * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;
  const currentData = data
    ? data?.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  return currentData;
};

//User Role

export const getUserRolesAndPermissions = () => {
  const access_token = JSON.parse(localStorage.getItem('userTokenData')||"")?.data?.access_token?.toString();

  try {
    const getUserData: any = jwtDecode(access_token);
    const serviceProDevRoles =
      getUserData.resource_access["ServicePro-Dev"].roles;
    return serviceProDevRoles;
  } catch (error) {
    console.error("Error decoding token:", error);
  }
};

export const getUserData = () => {
  const token_data = localStorage.getItem("token_data")?.toString();
  const access_token = token_data && JSON.parse(token_data).access_token;

  try {
    const getUserData: any = access_token&&jwtDecode(access_token);
   
    return getUserData;
  } catch (error) {
    console.error("Error decoding token:", error);
  }
};

export const isInvalid = (value:any) => {
  return value === null || String(value).trim() === "";
};
