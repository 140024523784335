import React, { useState, useEffect } from "react";
import * as ABB from "@abb/abb-common-ux-react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { getAllApplications } from "./services/myPnpServices";
import { useNavigate } from "react-router-dom";

const SessionControl = () => {
  const [activeClass, setActiveClass] = useState("");
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [permitted, setPermitted] = useState(true);
  const [showPermissionModal, setShowPermissionModal] = useState(false); // New state for modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    setActiveClass("");
    callGetAllApplications();
  }, []);

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const handleSessionValue = (val: any) => {
    setActiveClass(val);
  };

  const sessonTimes = [
    "7 days",
    "30 days",
    "60 days",
    "90 days",
    "Never logout automatically",
    "custom",
  ];

  return (
    <div className="session-control-section">
      {!permitted &&
        showPermissionModal && ( // Conditional rendering of the modal
          <div className="modal-overlay">
            <div className="modal red-border">
              <div className="modal-content">
                <button
                  className="modal-close"
                  onClick={() => {
                    navigate("/home");
                  }}                >
                  &times;
                </button>
                <p>
                  You don’t have the required permissions to access Platform
                  Administration Application. Please contact the administrator.
                </p>
              </div>
            </div>
          </div>
        )}
      {permitted === true && (
        <>
          <h1 className="session-control-heading">{general_text_data.session_control}</h1>
          <div className="session-control-container">
            <h4 className="session-control-h4">{general_text_data.select_session_type}</h4>
            <p>{general_text_data.session_type}</p>
            <div className="session-options">
              <div className="opt1">
                <div className="formEle">
                  <div className="input-holder">
                    <input type="radio" name="r1" />
                  </div>
                  <div className="label-holder">
                    {general_text_data.no_activity_timeout}
                  </div>
                </div>
                <p>{general_text_data.no_activity_timeout_desc}</p>
              </div>
              <div className="opt2">
                <div className="formEle">
                  <div className="input-holder">
                    <input type="radio" name="r1" />
                  </div>
                  <div className="label-holder">
                    {general_text_data.session_expiry}
                  </div>
                </div>
                <p>{general_text_data.session_expiry_desc}</p>
              </div>
            </div>
            <div className="session-timing-container">
              <h4>{general_text_data.logut_every} :</h4>
              <div className="session-opts">
                {sessonTimes.map((item, i) => {
                  const itemName = item[0].toUpperCase() + item.slice(1)
                  return (
                    <div
                      className={
                        i === 0
                          ? "session-block first"
                          : i === 5
                          ? "session-block last"
                          : "session-block"
                      }
                    >
                      <button
                        className={
                          activeClass === item
                            ? `session_control_btn activeBtn`
                            : "session_control_btn"
                        }
                        onClick={() => handleSessionValue(item)}
                      >
                        {itemName}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="session-control-buttons">
                <ABB.Button
                  text={"Cancel"}
                  shape="pill"
                  className="cancel-button"
                  //onClick={() => showNewPageCancel()}
                />
                <ABB.Button
                  className="btn-save"
                  type="primary-blue"
                  text={"Save"}
                  shape="pill"
                  // onClick={handleSave}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SessionControl;
