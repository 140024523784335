import { HMI } from "pnp-common-hmi";
import { useEffect, useRef } from "react";
import { useHMI } from "./app_context/HMIContext";
import { useUserContext } from "./app_context/UserContext";
import { getAllApplicationConfig } from "./services/myPnpServices";

export default function HMIComponent({ children }: any) {
  const hmiRef = useRef<any>(null);
  const { HMIData, setHMIData } = useHMI();
  const { setUserData } = useUserContext();

  // const triggerNestedFunction = () => {
  //   if (hmiRef.current) {
  //     hmiRef.current.triggerNestedFunction();
  //   }
  // };
  useEffect(() => {
    setUserData((prevData: any) => ({
      ...prevData,
      callRefreshToken: hmiRef.current?.refreshToken,
    }));
  }, [hmiRef]);
  useEffect(() => {
    fetchAllAppsConfig();
  }, []);

  const fetchAllAppsConfig = async () => {
    try {
      const serviceResp = await getAllApplicationConfig(1);
      const extensionResp = await getAllApplicationConfig(2);
      const platformResp = await getAllApplicationConfig(3);
      console.log(serviceResp, extensionResp, platformResp);
      const myApps = [];

      if (serviceResp.length > 0) {
        const modifiedServiceApps = {
          name: "Service Apps",
          applications: serviceResp.map((data: any) => ({
            name: data.name,
            icon: `/assets/images/${data.icon}`,
            url: data.externalUrl || data.embedUrl,
            newTab: data.newTab,
            appRegid: data.appRegid,
          })),
        };
        myApps.push(modifiedServiceApps);
      }

      if (extensionResp.length > 0) {
        const modifiedExtensionApps = {
          name: "Extensions",
          applications: extensionResp.map((data: any) => ({
            name: data.name,
            icon: `/assets/images/${data.icon}`,
            url: data.externalUrl || data.embedUrl,
            newTab: data.newTab,
            appRegid: data.appRegid,
          })),
        };

        myApps.push(modifiedExtensionApps);
      }

      if (platformResp.length > 0) {
        const newEntry = {
          appRegid: "Application provision",
          name: "Application Provisioning",
          description:
            "Setting up and configuring applications on My Pulp and Paper platform to ensure they are ready for use by end-users.",
          applicationSecurityId: null,
          applicationTypeId: 3,
          applicationTypeName: "Platform Administrator",
          adclient: null,
          embedUrl:
            "/platform_administration/manage_applications/Application_Provisioning",
          externalUrl:
            "/platform_administration/manage_applications/Application_Provisioning",
          icon: "icon_applications_provision.svg",
          isActive: true,
          newTab: false,
          valuePillar: "Platform Administrator",
          tileOrder: 4,
        };
        platformResp.push(newEntry);

        const modifiedPlatformApps = {
          name: "Platform Administration",
          applications: platformResp.map((data: any) => ({
            name: data.name,
            icon: `/assets/images/${data.icon}`,
            url: data.externalUrl || data.embedUrl,
            newTab: data.newTab,
            appRegid: data.appRegid,
          })),
        };
        myApps.push(modifiedPlatformApps);
      }

      setHMIData({
        ...HMIData,
        header_props: {
          ...HMIData.header_props,
          my_apps: myApps,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HMI
      {...HMIData}
      ref={hmiRef}
      getToken={(data: any) => {
        setUserData((prevData: any) => ({ ...prevData, data }));
      }}
      callReAuthenticate={() => console.log("second")}
    >
      {children}
    </HMI>
  );
}
