import { useContext, createContext } from "react";
import jsonData from "../app_data/app_data.json";
import generalTextData from "../app_data/generalTextData.json";
import imagesData from "../app_data/images_data.json";

const {
  REACT_APP_URL_PREFIX,
  REACT_APP_ENV_PREFIX,
  REACT_APP_DOMAIN,
  REACT_APP_LOCATION,
  REACT_APP_KEYCLOAK,
} = process.env;

export const hostURL = `${REACT_APP_URL_PREFIX}${REACT_APP_ENV_PREFIX}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;

type MyDictionary = { [key: string]: any };
const side_props = (navigate: any) => ({
  home_url: "home",
  my_apps_url: "my_apps",
  page_name: "test1",
  applications: [
    {
      name: `Service Apps`,
      id: 1,
      url: "/service_apps",
      icon: "/assets/images/icon_services.svg",
      icon_active: "/assets/images/icon_services_active.svg",
      sub_menu: [],
    },
    {
      name: `Extensions`,
      id: 2,
      url: `/extensions`,
      icon: `/assets/images/icon_core_extension.svg`,
      icon_active: `/assets/images/icon_core_extension_active.svg`,
      sub_menu: [],
    },
    {
      name: `Platform Administration`,
      id: 3,
      url: `/platform_administration`,
      icon: `/assets/images/icon_parts.svg`,
      icon_active: `/assets/images/icon_parts_active.svg`,
      // sub_menu: [
      //   {
      //     name: `Roles User Management`,
      //     id: 1,
      //     url: `/platform_administration/roles_user_management/role_definition`,
      //     icon: `/assets/images/icon_platform_administration.svg`,
      //     icon_active: `/assets/images/icon_platform_administration_active.svg`,
      //     sub_menu: [
      //       {
      //         name: `Role Definition`,
      //         id: 1,
      //         url: `/platform_administration/roles_user_management/role_definition`,
      //         icon: `/assets/images/icon_role_definition.svg`,
      //         icon_active: `/assets/images/icon_role_definition_active.svg`,
      //         sub_menu: [],
      //       },
      //       {
      //         name: `User Mapping And Role`,
      //         id: 1,
      //         url: `/platform_administration/roles_user_management/user_mapping`,
      //         icon: `/assets/images/icon_user_mapping.svg`,
      //         icon_active: `/assets/images/icon_user_mapping_active.svg`,
      //         sub_menu: [],
      //       },
      //       {
      //         name: `Activity Log`,
      //         id: 1,
      //         url: "/platform_administration/roles_user_management/activity_logs",
      //         icon: "/assets/images/icon_activity_logs.svg",
      //         icon_active: "/assets/images/icon_activity_logs_active.svg",
      //         sub_menu: [],
      //       },
      //       {
      //         name: `Session Control`,
      //         id: 1,
      //         url: `/platform_administration/session_control`,
      //         icon: `/assets/images/icon_session_control.svg`,
      //         icon_active: `/assets/images/icon_session_control_active.svg`,
      //         sub_menu: [],
      //       },
      //     ],
      //   },
      //   {
      //     name: `Activity Log`,
      //     id: 1,
      //     url: `/platform_administration/roles_user_management/activity_logs`,
      //     icon: `/assets/images/icon_activity_logs.svg`,
      //     icon_active: `/assets/images/icon_activity_logs_active.svg`,
      //     sub_menu: [],
      //   },
      //   {
      //     name: `Application Provisioning`,
      //     id: 1,
      //     url: `/platform_administration/manage_applications/Application_Provisioning`,
      //     icon: `/assets/images/icon_my_apps.svg`,
      //     icon_active: `/assets/images/icon_my_apps.svg`,
      //     sub_menu: [
      //       {
      //         name: `Application Provisioning`,
      //         id: 1,
      //         url: `/platform_administration/manage_applications/Application_Provisioning`,
      //         icon: `/assets/images/icon_my_apps.svg`,
      //         icon_active: `/assets/images/icon_my_apps.svg`,
      //         sub_menu: [],
      //       },
      //       {
      //         name: `Import/Export Application`,
      //         id: 1,
      //         url: `/platform_administration/manage_applications/Import_Export_Application`,
      //         icon: `/assets/images/export-icon.svg`,
      //         icon_active: `/assets/images/export-icon_active.svg`,
      //         sub_menu: [],
      //       },
      //     ],
      //   },
      //   {
      //     name: `Session Control`,
      //     id: 1,
      //     url: `/platform_administration/session_control`,
      //     icon: `/assets/images/icon_session_control.svg`,
      //     icon_active: `/assets/images/icon_session_control_active.svg`,
      //     sub_menu: [],
      //   },
      // ],
      sub_menu: [],
    },
  ],
  contact_abb: "/contact_abb",
  onLinkClick: (url: string) => {
    navigate(url);
  },
});

let service_pro_url = "https://stage.in.iapipulpandpaper.abb.com/servicepro";
const window_url = window.location.href;
if (window_url.includes("dev.in")) {
  service_pro_url = service_pro_url.replace("stage.in", "dev.in");
}

const app_menu = {
  name: `Service Apps`,
  applications: [
    {
      name: "Service Pro",
      icon: "/assets/images/icon_service_pro.svg",
      url: service_pro_url,
    },
    {
      name: "My QCS",
      icon: "/assets/images/icon_my_qcs.svg",
      url: "/my_qcs",
    },
  ],
};
const app_menu2 = {
  name: `Extensions`,
  applications: [
    {
      name: "CoC_ThirdParty",
      icon: "/assets/images/icon_demo_app_2.svg",
      url: "/coc_third_party_widget_demo",
    },
    {
      name: "Profile Historian",
      icon: "/assets/images/icon_profile_historian.svg",
      url: "/profile_Historian",
    },
    {
      name: `Winder Performance`,
      icon: `/assets/images/icon_winder_performance.svg`,
      url: `/extended_solutions/winder_performance`,
    },
  ],
};

const app_menu3 = {
  name: `Platform Administration`,
  applications: [
    {
      name: `Activity Logs`,
      icon: `/assets/images/icon_activity_logs.svg`,
      url: `/platform_administration/roles_user_management/activity_logs`,
    },
    {
      name: `Roles & User Management`,
      icon: `/assets/images/icon_about.svg`,
      url: `/platform_administration/roles_user_management/role_definition`,
    },
    {
      name: `Session Control`,
      icon: `/assets/images/icon_session_control.svg`,
      url: `/platform_administration/session_control`,
    },
  ],
};

const header_props = {
  application_name: `My Pulp and Paper`,
  my_apps: [app_menu, app_menu2, app_menu3],
  auth_url: `${hostURL}/authservapi/api/Auth/GetTokenByAADCode`,
  auth_apis: undefined,
  refresh_ep: `RefreshAccessToken`,
  logout_ep: `Logout`,
  ref: "hmiRef",
  getToken: () => console.log("first"),
  callReAuthenticate: () => console.log("second"),
};
// Application Type APIs
const application_apis: MyDictionary = {
  "dev.in.iapipulpandpaper.abb.com": `https://dev.in.iapipulpandpaper.abb.com/provisioningapi/api/ApplicationConfiguration`,
  "dev.us.iapipulpandpaper.abb.com": `https://dev.us.iapipulpandpaper.abb.com/provisioningapi/api/ApplicationConfiguration`,
  "stage.in.iapipulpandpaper.abb.com": `${hostURL}/provisioningapi/api/ApplicationConfiguration`,
  "stage.us.iapipulpandpaper.abb.com": `${hostURL}/provisioningapi/api/ApplicationConfiguration`,
};
const application_api = `${hostURL}/provisioningapi/api/ApplicationConfiguration`;

//Application APIs
const authorization_apis: MyDictionary = {
  "dev.in.iapipulpandpaper.abb.com": `https://dev.in.iapipulpandpaper.abb.com/authservapi/api`,
  "stage.in.iapipulpandpaper.abb.com": `${hostURL}/authservapi/api`,
  "dev.us.iapipulpandpaper.abb.com": `https://dev.us.iapipulpandpaper.abb.com/authservapi/api`,
  "stage.us.iapipulpandpaper.abb.com": `${hostURL}/authservapi/api`,
};
const authorizaion_api = `${hostURL}/authservapi/api`;

const REACT_APP_ENV_DOMAIN = `${REACT_APP_ENV_PREFIX}${REACT_APP_KEYCLOAK}${REACT_APP_LOCATION}${REACT_APP_DOMAIN}`;

const suffix = `/realms/MyPulpAndPaper/protocol/openid-connect/auth?response_type=code&client_id=ServicePro`;
const login_props = {
  landing: `home`,
  keycloaks: undefined,
  suffix: suffix,
  keycloak_url: REACT_APP_ENV_DOMAIN,
};
const footer_props = {
  copyright: `Copyright 2024 - 25 ABB`,
  tzname: `UTC`,
  utc_offset: `+00:00`,
  tz_ep: `${hostURL}/provisioningapi/api/UserPreference/getUserPreferenceTimeLocale`,
};

const userPreference_props = {
  localTime: [],
  allApplicationType: [],
  timezoneSelected: "UTC",
  usertime_ep: `${hostURL}/provisioningapi/api//UserPreference/getUserPreferenceTimeLocale`,
  alltime_ep: `${hostURL}/provisioningapi/api//UserPreference/getAllTimeZone`,
  allCategoryApplications: `${hostURL}/provisioningapi/api/ApplicationConfiguration/getAllApplicationsGroupedByCategory`,
  createUserPreference: `${hostURL}/provisioningapi/api/UserPreference/createUserPreference`,
};

const hmi_props = {
  header_props: header_props,
  // side_props: side_props,
  login_props: login_props,
  footer_props: footer_props,
  userPreference_props: userPreference_props,
};

export const getHMIProps = (navigate: any): { [key: string]: any } => {
  return {
    ...hmi_props,
    side_props: side_props(navigate),
  };
};

export const HMIProps = createContext(hmi_props);
export const AppContext = createContext({ jsonData });
export const AppGeneralTextContext = createContext(generalTextData);
export const ImagesContext = createContext(imagesData);
export const AppApiz = createContext(application_apis);
export const AppApi = createContext(application_api);
export const AuthApiz = createContext(authorization_apis);
export const AuthApi = createContext(authorizaion_api);
export const useAppGeneralText = () => {
  return useContext(AppGeneralTextContext);
};
