import React, { useEffect, useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import * as ABB from "@abb/abb-common-ux-react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { buildNotification } from "./utils/utils";
import { useNotification } from "./app_context/NotificationContext";
import {
  AssignedRoleToUser,
  getAllApplications,
  getAllRoles,
  getUserDetailsWithAppltnRole,
} from "./services/myPnpServices";
import { useNavigate } from "react-router-dom";
import {
  MUIButton,
  MUISelect,
  MUITextField,
  Search,
} from "@abb/abb-arcadia-ui-react";

export interface AllRoles {
  clientRole: boolean;
  composite: boolean;
  description: string;
  id: string;
  name: string;
  usersInRole: any;
  usersInRoleCount: number;
  container_id: string;
  displayName?: string;
}
export interface AllUsersList {
  id: string;
  name: string;
}
export interface AllApplications {
  client_Name: string;
  client_id: string;
  application: string;
}
export interface roleType {
  id: string;
  name: string;
  description: string;
  composite: boolean;
  clientRole: boolean;
  containerId: string;
}

const AssignRole = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const [filteredRoles, setFilteredRoles] = useState<AllRoles[]>([]);
  const { showNotification } = useNotification();
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [user, setUser] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<any[]>([]);
  const [singleRoles, setSingleRoles] = useState<any>();
  const [allUsers, setAllUsers] = useState<AllUsersList[]>([]);
  const [userDetails, setUserDetails] = useState<any[]>([]);
  const [taggedRoles, setTaggedRoles] = useState<any[]>([]);
  const [query, setQuery] = useState("");

  const [allApplications, setAllApplications] = useState<AllApplications[]>();

  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    callGetAllApplications();
  }, []);

  useEffect(() => {
    if (clientIds.length > 0) {
      callGetUserRoles(selectedCategory.label);
    }
  }, [selectedCategory, clientIds]);

  useEffect(() => {
    getUserDetails(query);
  }, [query]);

  const handleRoleSelect = (value: any) => {
    setSingleRoles(value);
    const isRoleSelected = selectedRoles.some(
      (role: any) => role.value === value.value
    );
    const isRoleTagged = taggedRoles.some(
      (role: any) => role.value === value.value
    );
    if (!isRoleSelected && !isRoleTagged) {
      let role = {
        value: value.value,
        label: value.label,
      };
      setSelectedRoles((prevRoles) => [...prevRoles, role]);
      setTaggedRoles((prevRoles) => [...prevRoles, role]);
    }
  };
  const handleRemoveRole = (role: any) => {
    setTaggedRoles(taggedRoles.filter((r: any) => r.value !== role.value));
    setSelectedRoles(selectedRoles.filter((r: any) => r.value !== role.value));
    setSingleRoles([]);
  };
  const handleSave = () => {
    const selectedRolesData = taggedRoles
      .map((role: any) =>
        allRoles.find((allRole: any) => allRole.id === role.value)
      )
      .filter(Boolean);

    let payload = selectedRolesData.map((role: any) => ({
      id: role.id,
      name: role.name,
      description: role.description,
      composite: role.composite,
      clientRole: role.clientRole,
      containerId: role.container_id,
    }));
    assignRoles(payload);
  };

  const assignRoles = async (roles: roleType[]) => {
    try {
      let response = await AssignedRoleToUser(
        allApplications && allApplications[0].client_id,
        user?.id,
        user?.username,
        roles
      );
      if (response) {
        showNotification([
          buildNotification(
            "New role is assigned successfully to the selected user!",
            "success"
          ),
        ]);
        navigate("/platform_administration/roles_user_management/user_mapping");
      }
    } catch (err: any) {
      showNotification([
        buildNotification("Failed to assign role to user!", "alarm"),
      ]);
    }
  };

  const callGetAllApplications = async () => {
    try {
      let result = await getAllApplications();
      if (result.length > 0) {
        let applications: AllApplications[] = result;
        const clientIdsArray = applications.map((app) => app.client_id);
        setClientIds(clientIdsArray);
        setAllApplications(applications);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        showNotification([buildNotification(err.response.data, "alarm")]);
      }
    }
  };

  const callGetUserRoles = async (category: string) => {
    try {
      const response = await getAllRoles(clientIds);
      if (response.data?.length > 0) {
        setAllRoles(response.data);
        const filteredData = response.data?.filter((item: any) => {
          if (category === "Extensions") {
            return item.name.startsWith("Extensions");
          } else if (category === "Service Apps") {
            return item.name.startsWith("Service Apps");
          }
          return false;
        });

        const updatedData = filteredData.map((role: any) => {
          const displayName = role.name.includes(":")
            ? role.name.split(":")[1]
            : role.name;

          return {
            ...role,
            displayName,
          };
        });

        setFilteredRoles(updatedData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDetails = async (query: string) => {
    try {
      let payload = {
        start: 0,
        max: 10,
        search: query,
      };
      let result = await getUserDetailsWithAppltnRole(payload);
      if (result) {
        setAllUsers(result.data);
        let users: AllUsersList[] = [];
        result.data.map((user: { id: any; email: any }) => {
          users.push({ id: user.id, name: user.email });
        });
        setAllUsers(users);
        setUserDetails(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnSearch = (search: string, results: any) => {
    setQuery(search);
  };

  const handleOnSelect = (item: any) => {
    const foundUserData = userDetails.find(
      (d: any) => d.email.toLowerCase() === item.name.toLowerCase()
    );

    if (foundUserData) {
      setUser(foundUserData);

      let roles: any = [];
      allRoles?.forEach((role) => {
        let includeRole = true;
        foundUserData?.roles.forEach((userRole: { id: string }) => {
          if (userRole.id === role.id) includeRole = false;
          return;
        });
        if (includeRole === true) roles.push(role);
      });

      let existingRoles: any[] = foundUserData?.roles.map((userRole: any) => {
        let matchedRole = allRoles.find((role: any) => role.id === userRole.id);
        return {
          value: matchedRole?.id,
          label: matchedRole?.name,
        };
      });

      setSelectedRoles([]);
      setTaggedRoles(existingRoles);
    }
  };
  const handleCategoryValue = (value: any) => {
    setSelectedCategory(value[0]);
    setSingleRoles([]);
  };

  return (
    <>
      <div className="assign-role-container">
        <div>
          <h1>{general_text_data.user_mapping_and_role}</h1>
        </div>
        <div className="flex-justify-end">
          <MUIButton
            label={general_text_data.invite_new_user}
            onClickButton={() =>
              navigate(
                "/platform_administration/user_mapping/invite_new_user",
                {
                  state: {
                    type: "admin_request",
                    application: allApplications,
                  },
                }
              )
            }
            size={32}
            variant="secondary"
          />
        </div>
        <div className="assign-role-content-container">
          <div className="assign-role-title-container">
            <h3 className="assign-role-title">
              {general_text_data.assign_role}
            </h3>
          </div>
          <div className="input-container">
            <div className="lhs-input-column-one">
              <div className="assign-role-input">
                <label htmlFor="user">{general_text_data.user}</label>
                <ReactSearchAutocomplete
                  items={allUsers}
                  onSearch={handleOnSearch}
                  onSelect={handleOnSelect}
                  onClear={() => {
                    setUser({});
                    setSelectedRoles([]);
                    setTaggedRoles([]);
                  }}
                  placeholder="Search user"
                  autoFocus
                  className="autosearch"
                />
              </div>
              <div className="assign-role-input">
                <label className="new-role-label">Select Category :</label>

                <ABB.Dropdown
                  onChange={(val) => {
                    handleCategoryValue(val);
                  }}
                  value={selectedCategory}
                  multiselect={false}
                  placeholder="Select Application"
                >
                  {allApplications?.map((e: any) => (
                    <ABB.DropdownOption
                      key={e.applicationCategoryId}
                      label={e.client_Name}
                      value={e.applicationCategoryId}
                    />
                  ))}
                </ABB.Dropdown>
              </div>
              <div className="assign-role-input">
                <label htmlFor="role">{general_text_data.role}</label>

                <ABB.Dropdown
                  onChange={(val) => handleRoleSelect(val[0])}
                  value={singleRoles}
                  multiselect={false}
                  placeholder="Select Application"
                >
                  {filteredRoles?.map((e: any) => (
                    <ABB.DropdownOption
                      key={e.id}
                      label={e.displayName}
                      value={e.id}
                    />
                  ))}
                </ABB.Dropdown>
              </div>
            </div>
            <div className="rhs-text-area-container-and-btn-group">
              {taggedRoles && taggedRoles.length > 0 && (
                <div className="togged-roles">
                  <h3 className="togged-role-text">
                    {general_text_data.togged_roles}
                  </h3>
                  <div>
                    <div className="card-container">
                      {taggedRoles?.map((role: any, index: any) => (
                        <div key={index} className="role-card">
                          <span>{role.label}</span>
                          <button onClick={() => handleRemoveRole(role)}>
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="assign-action-buttons">
            <MUIButton
              label={general_text_data.cancel}
              onClickButton={() =>
                navigate(
                  "/platform_administration/roles_user_management/user_mapping"
                )
              }
              size={32}
              variant="secondary"
            />
            <MUIButton
              label={general_text_data.save}
              onClickButton={handleSave}
              size={32}
              variant="primary"
              disabled={
                !user ||
                user.lenght === 0 ||
                selectedRoles.length === 0 ||
                selectedRoles === null
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignRole;
