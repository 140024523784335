import React, { useState } from "react";
import { AppGeneralTextContext } from "./app_context/app_context";
import { ImagesContext } from "./app_context/app_context";
import { MUIButton, MUITextField } from "@abb/abb-arcadia-ui-react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const MyQcs = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { general_text_data }: any = appGeneralTextData;
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;
  const handleTabClick = (index: number) => {
    setActiveTabIndex(index);
  };
  return (
    <div className="services-container">
      <div className="intro-header">
        <h1>My Learning</h1>
      </div>

      <div className="container">
        <div className="webinars-section">
          <div className="heading_section">Webinars</div>
          <div className="webinar-list">
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img src={images.img_papper_making} alt="Papermaking Process" />
              </div>
              <div className="col-6 webinar-content">
                <a href="#">The papermaking process</a>
                <div className="webinar-content_des">Pulp and papermaking are complex, continuous processes. We break both down in a digestible way to understand the many opportunities we have to support our customers.</div>
                <a href="#">Watch on-demand webinar</a>
              </div>
            </div>
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img src={images.img_abb_solution} alt="Solutions for Pulp" />
              </div>
              <div className="col-6 webinar-content">
                <a href="#">
                  ABB’s solutions for pulp, paper, board and tissue
                </a>
                <div className="webinar-content_des">
                With 366 active product tree nodes – we have a lot to offer our customers! Learn the breadth of the portfolio to understand what solutions you could be offering customers and / or promoting in your local markets! 
                </div>
                <a href="#">Watch on-demand webinar</a>
              </div>
            </div>
            <div className="grid-container webinar-item gap-20">
              <div className="col-5 webinar_img_section">
                <img
                  src={images.img_pulp_papper_training}
                  alt="Training & Launches"
                />
              </div>
              <div className="col-6 webinar-content">
                <a href="#">Pulp & Paper training, recordings & launches</a>
                <div className="webinar-content_des">Stay up to date on the latest from ABB Pulp & Paper and catch up on all of our training sessions, webinar recordings, and product launches.</div>
                <a href="https://go.insideplus.abb.com/business-areas-and-divisions/process-automation/division-portals/process-industries/business-lines/business-line-pulp-and-paper/internal-webinars-product-launches">Visit our internal launch hub</a>
              </div>
            </div>
          </div>
          <div className="webinar_section_btn view_more_btn">
            <MUIButton
              label="View More"
              // onClickButton={handleNewApplicationClick}
              size={32}
              variant="primary"
            />{" "}
          </div>
        </div>
        <div className="trainings-section">
          <div className="heading_section">Trainings</div>
          <div className="training-box">
            <div className="trainings-section_us_training">US Training’s</div>
            <div className="trainings-section_sub_heading">
              <p>My Pulp & Paper Trainings</p>
              <a href="https://go.insideplus.abb.com/business-areas-and-divisions/process-automation/division-portals/process-industries/business-lines/business-line-pulp-and-paper/pulp-paper-sales-support#portfolio">
                https://go.insideplus.abb.com/business-areas-and-divisions/
                process-automation/division-portals/process-industries/business-lines/
                business-line-pulp-and-paper/pulp-paper-sales-support#portfolio
              </a>
            </div>
            <div className="trainings-section_sub_heading">
              <p>My Pulp & Paper Trainings</p>
              <a href="https://go.insideplus.abb.com/business-areas-and-divisions/process-automation/division-portals/process-industries/business-lines/business-line-pulp-and-paper/pulp-paper-sales-support#portfolio">
                https://go.insideplus.abb.com/business-areas-and-divisions/
                process-automation/division-portals/process-industries/business-lines/
                business-line-pulp-and-paper/pulp-paper-sales-support#portfolio
              </a>
            </div>
            <div className="view_more_btn">
              <MUIButton
                label="View More"
                // onClickButton={handleNewApplicationClick}
                size={32}
                variant="primary"
              />
            </div>
          </div>
          <div className="training_ie_section">
            <div className="trainings-section_us_training">IE Training’s</div>

            <div className="grid-container trainings-section_item gap-5">
              <div className="col-6">
                <div className="trainings-section_us_training">
                  MyLearning Course Registration
                </div>
                <div className="table-column">
                  <div>Controls</div>
                  <a href="#">C103 - QCS Machine-Direction Control - IE</a>
                  <a href="#">C109 - QCS Multivariable CD Control - IE</a>
                  <a href="#">C150 - QCS Advanced Color Control - IE</a>
                </div>
                <div className="table-column">
                  <div>Systems</div>
                  <a href="#">C302 - QCS800xA Essentials - IE</a>
                  <a href="#">C306 - QCS800xA Advanced Concepts - IE</a>
                </div>
                <div className="table-column">
                  <div>Scanners & Sensors</div>
                  <a href="#">C235 - Network Platform with QCS LAN - IE</a>
                  <a href="#">C315 - High Performance Sensor - IE</a>
                  <a href="#">C311 - QCS Sensor Correlation - IE</a>
                </div>
              </div>
              <div className="col-6">
                <div className="trainings-section_us_training">
                  Course Descriptions
                </div>
                <div className="table-column">
                  <div>Controls</div>
                  <a href="#">C103 Course Description</a>
                  <a href="#">C105 Course Description</a>
                </div>
                <div className="table-column">
                  <div>Systems</div>
                  <a href="#">C107 Course Description</a>
                  <a href="#">C109 QCS Multivariable Cross Direction Control</a>
                </div>
                <div className="table-column">
                  <div>Scanners & Sensors</div>
                  <a href="#">C302 ABB QCS800xA Essentials</a>
                  <a href="#">C306 ABB QCS800xA Advanced Concepts</a>
                </div>
              </div>
            </div>
            {/* tabs united state and ierland */}
            <Tabs
              selectedIndex={activeTabIndex}
              onSelect={(index: any) => handleTabClick(index)}
            >
              <div className="my_learning_tabs">
                <TabList className="react-tabs__tab-list tab-container-tabItems">
                    <Tab key={"united states"}>{"United States"}</Tab>
                    <Tab key={"Ireland"}>{"Ireland"}</Tab>
                </TabList>
              </div>
            </Tabs>
            <div className="grid-container trainings-section_item gap-5">
              <div className="col-6">
                <div className="trainings-section_us_training">
                  MyLearning Course Registration
                </div>
                <div className="table-column">
                  <div>Controls</div>
                  <a href="https://mylearning.abb.com/coursepage/22987_enUS/ExpertusONE_1">C103 - QCS Machine-Direction Control - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/23085_enUS/ExpertusONE_1">C109 - QCS Multivariable CD Control - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/33282_enUS/ExpertusONE_1">C150 - QCS Advanced Color Control - IE</a>
                </div>
                <div className="table-column">
                  <div>Systems</div>
                  <a href="https://mylearning.abb.com/coursepage/22988_enUS/ExpertusONE_1">C302 - QCS800xA Essentials - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/23088_enUS/ExpertusONE_1">C306 - QCS800xA Advanced Concepts - IE</a>
                </div>
                <div className="table-column">
                  <div>Scanners & Sensors</div>
                  <a href="https://mylearning.abb.com/coursepage/23098_enUS/ExpertusONE_1">C235 - Network Platform with QCS LAN - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/22986_enUS/ExpertusONE_1">C315 - High Performance Sensor - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/38818_enUS/ExpertusONE_1">C311 - QCS Sensor Correlation - IE</a>
                </div>
                <div className="table-column">
                  <div>Actuators</div>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                </div>
                <div className="table-column">
                  <div>Sales</div>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                </div>
              </div>
              <div className="col-6">
                <div className="trainings-section_us_training">
                  Course Descriptions
                </div>
                <div className="table-column">
                  <div>Controls</div>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095099&LanguageCode=en&DocumentPartId=&Action=Launch">C103 Course Description</a>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095100&LanguageCode=en&DocumentPartId=&Action=Launch">C105 Course Description</a>
                </div>
                <div className="table-column">
                  <div>Systems</div>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS095101&LanguageCode=en&DocumentPartId=&Action=Launch">C107 Course Description</a>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A1835&LanguageCode=en&DocumentPartId=&Action=Launch">C109 QCS Multivariable Cross Direction Control</a>
                </div>
                <div className="table-column">
                  <div>Scanners & Sensors</div>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=3BUS222456-1&LanguageCode=en&DocumentPartId=&Action=Launch">C302 ABB QCS800xA Essentials</a>
                  <a href="https://search.abb.com/library/Download.aspx?DocumentID=9AKK108469A1055&LanguageCode=en&DocumentPartId=&Action=Launch">C306 ABB QCS800xA Advanced Concepts</a>
                </div>
                <div className="table-column">
                  <div>Actuators</div>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                </div>
                <div className="table-column">
                  <div>Sales</div>
                  <a href="https://mylearning.abb.com/coursepage/23100_enUS/ExpertusONE_1">C400 - Weight xP II Profiler - IE</a>
                </div>
              </div>
            </div>
            {/* tabs united state and ierland end */}
            <div className="view_more_btn">
              <MUIButton
                label="View More"
                // onClickButton={handleNewApplicationClick}
                size={32}
                variant="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyQcs;
