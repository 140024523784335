import React, { useState, useEffect, useMemo } from "react";
import "react-tabulator/lib/styles.css";
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css";
import { AppGeneralTextContext } from "./app_context/app_context";
import { Input } from "@abb/abb-common-ux-react";
import * as ABB from "@abb/abb-common-ux-react";
import NewRole from "./new_role";
import { useNotification } from "./app_context/NotificationContext";
import { buildNotification, buildPaginationData } from "./utils/utils";
import {
  deleteUserRole,
  getAllApplications,
  getAllRoles,
} from "./services/myPnpServices";
import { MyPnPDataGrid } from "pnp-components-library";
import { useNavigate } from "react-router-dom";
import { MUIButton, MUITextField, Pagination } from "@abb/abb-arcadia-ui-react";

export interface TableData {
  id: string;
  role: string;
  description: string;
  lastupdated: string;
  users: number;
  active_inactive: boolean;
  email: string;
  region: string;
  roles: any;
  updated: boolean;
}
interface DeleteUser {
  user: string;
  role: any;
  rowData: any;
  updatedRole: any;
}

export interface AllApplications {
  client_Name: string;
  client_id: string;
  application: string;
}

interface AllRoles {
  clientRole: boolean;
  composite: boolean;
  description: string;
  id: string;
  name: string;
  usersInRole: any;
  usersInRoleCount: number;
  container_id: string;
}

const RoleDefinition = () => {
  const [allRoles, setAllRoles] = useState<AllRoles[]>([]);
  const [allRolesOrg, setAllRolesOrg] = useState<AllRoles[]>([]);
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const [deleteDialogOpen, setdeleteDialogOpen] = useState(false);
  const [showNewRole, setShowNewRole] = useState(false);
  const [permitted, setPermitted] = useState(true);
  const { showNotification } = useNotification();
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [clientId, setClientId] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [deleteUser, setDeleteUser] = useState<DeleteUser>();
  const applicationPlaceHolder: AllApplications[] = [
    { client_Name: "Select Applications", client_id: "", application: "" },
  ];
  const [allApplications, setAllApplications] = useState<AllApplications[]>(
    applicationPlaceHolder
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();

  useEffect(() => {
    callGetAllApplications();
  }, []);

  useEffect(() => {
    if (clientId.length > 0) {
      callGetUserRoles();
    }
  }, [clientId]);

  const onSearchValueChange = (value: string) => {
    onSearchIconClick(value);
    setSearchValue(value);
    onSearchIconClick(value);
  };

  const onSearchIconClick = (value: any) => {
    let filtered =
      allRolesOrg &&
      allRolesOrg?.filter((ele) => {
        const roleName = ele.name || "";
        return roleName.toLowerCase().includes(value.toLowerCase());
      });
    setAllRoles(filtered);
  };

  const RolesDeleteComponent = (value: any): JSX.Element => {
    const rowData = value.data;
    return (
      <div>
        {rowData.usersInRoleCount > 0 ? (
          <ABB.Icon name="abb/trash" className="not-allowed" />
        ) : (
          <div
            onClick={() => {
              setDeleteUser(rowData);
              setdeleteDialogOpen(true);
            }}
          >
            <ABB.Icon name="abb/trash" className="cursor-pointer" />
          </div>
        )}
      </div>
    );
  };
  const onPageNumClick = (evt: any, pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const paginationConfig = useMemo(
    () => ({
      pageNumber: currentPage,
      pageSize: pageSize,
      totalRecords: allRoles?.length ?? 0,
      onPageClick: onPageNumClick,
    }),
    [currentPage, pageSize, allRoles]
  );

  const buildTableData = useMemo(() => {
    let currentUsersData = buildPaginationData(
      currentPage + 1,
      pageSize,
      allRoles
    );
    return currentUsersData ?? [];
  }, [currentPage, allRoles, pageSize]);

  const columns = [
    {
      field: "category",
      flex: 1,
    },
    {
      title: "Role",
      field: "name",
      flex: 1,
    },
    {
      title: "Description",
      field: "description",
      flex: 1,
    },
    { title: "Application Name", field: "applicationName", flex: 1 },
    {
      title: "Users Count",
      field: "usersInRoleCount",
      flex: 1,
    },
    {
      title: "Delete",
      field: "delete",
      cellRenderer: RolesDeleteComponent,
    },
  ];

  const openNewRolePage = () => {
    console.log("openNewRolePage");
    setShowNewRole(true);
  };

  const callGetAllApplications = async () => {
    try {
      const resp = await getAllApplications();

      if (resp.length > 0) {
        let applications: AllApplications[] = resp;
        const clientIdsArray = applications.map((app) => app.client_id);
        setClientId(clientIdsArray);
        setAllApplications(applications);
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const callGetUserRoles = async () => {
    try {
      const response = await getAllRoles(clientId);

      const transformedData = response?.data?.map((role: any) => {
        if (role.name && role.name.includes(":")) {
          const [category, name] = role.name.split(":");
          return { ...role, category, name: name || role.name };
        } else {
          return { ...role, category: null, name: role.name };
        }
      });

      setAllRoles(transformedData);
      setAllRolesOrg(transformedData);
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
        setShowPermissionModal(true);
      } else {
        console.log(err);
      }
    }
  };

  const DeleteRole = async (role: any) => {
    const category = allApplications?.find(
      (data: any) => data.client_id === role.container_id
    );
    const roleName = role.name.includes(":")
      ? role.name.split(":")[1]
      : role.name;
    let payload = {
      id: role.id,
      name: roleName,
      description: role.description,
      composite: true,
      clientRole: true,
      containerId: role.container_id,
      categoryName: category?.client_Name,
      applicationId: role.appRegId,
    };

    try {
      let response = await deleteUserRole(payload);
      if (response) {
        setdeleteDialogOpen(false);
        refreshCells({ name: role.name, containerId: role.container_id });
        showNotification([
          buildNotification(
            "Selected " + role.name + " is deleted sucessfully",
            "success"
          ),
        ]);
        callGetAllApplications();
      }
    } catch (err: any) {
      if (err.response && typeof err.response === "string") {
        showNotification([buildNotification(err.response, "alarm")]);
      } else {
        showNotification([buildNotification("Failed to Delete!", "alarm")]);
      }
    }
  };

  const handleDelete_Cancel = () => {
    setdeleteDialogOpen(false);
  };

  const handleRowsPerPage = (evt: any) => {
    const { value } = evt?.target || {};
    setPageSize(value);
  };

  const refreshCells = (Role: any) => {
    allRoles?.filter((role) => role.container_id !== Role.container_id);
    setAllRoles(allRoles?.filter((role) => role.name !== Role.name));
  };

  return (
    <div className="grid-conatiner">
      <div className="col-10">
        <div className="role-deinition-screen">
          {!permitted && showPermissionModal && (
            <div className="modal-overlay">
              <div className="modal red-border">
                <div className="modal-content">
                  <button
                    className="modal-close"
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    &times;
                  </button>
                  <p>
                    You don’t have the required permissions to access Platform
                    Administration Application. Please contact the
                    administrator.
                  </p>
                </div>
              </div>
            </div>
          )}

          {!showNewRole && permitted === true && (
            <>
              <h1 className="role-definition-heading">
                {general_text_data.role_definition}
              </h1>

              <div className="role-definition-search-input">
                <MUITextField
                  onChange={onSearchValueChange}
                  value={searchValue}
                  onKeyDown={() => {}}
                  placeholder={"Search by role name"}
                  minWidth={200}
                  size={32}
                />

                <MUIButton
                  label="New Role"
                  onClickButton={openNewRolePage}
                  size={32}
                  trailingIconName=""
                  variant="secondary"
                  iconName="icon-abb_plus"
                />
              </div>
              <div className="role-definition-table">
                <div className="roles-heading">Roles</div>
                <MyPnPDataGrid
                  columns={columns}
                  rowData={buildTableData || []}
                />
                {paginationConfig && (
                  <Pagination
                    count={paginationConfig.totalRecords}
                    handleChangePage={paginationConfig!.onPageClick}
                    handleChangeRowsPerPage={handleRowsPerPage}
                    page={paginationConfig.pageNumber}
                    rowsPerPage={paginationConfig.pageSize}
                    showPrevNext={false}
                    showNumberButtons={false}
                  />
                )}
              </div>
            </>
          )}
          {deleteDialogOpen && (
            <ABB.Dialog
              showCloseButton={true}
              closeOnEscape={true}
              closeOnLostFocus={true}
              hideBackground={false}
              isOpen={deleteDialogOpen}
              onClose={() => setdeleteDialogOpen(false)}
            >
              <div className="delete-role-dialog">
                <p className="info-icon">&#x1F6C8;</p>
                <h2 className="delete-heading">Delete</h2>
                <p className="delete-confirm-msg">
                  Are you sure you want to delete?
                </p>
              </div>
              <div className="btn-cancel-yes-delete-it-btn">
                <MUIButton
                  label="Cancel"
                  onClickButton={() => handleDelete_Cancel()}
                  size={32}
                  trailingIconName=""
                  variant="secondary"
                />
                <MUIButton
                  label="Yes, Delete it!"
                  onClickButton={() => DeleteRole(deleteUser)}
                  size={32}
                  trailingIconName=""
                  variant="primary"
                />
              </div>
            </ABB.Dialog>
          )}
          {showNewRole && <NewRole applications={allApplications} />}
        </div>
      </div>
    </div>
  );
};

export default RoleDefinition;
