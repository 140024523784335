import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Space } from "antd";
import {
  AppGeneralTextContext,
  ImagesContext,
} from "./app_context/app_context";
import { SEARCH_TRIGGER_LENGTH } from "./components/constants";
import BannerImage from "./banner_image";
import {
  getAllApplicationConfig,
  getAllApplications,
} from "./services/myPnpServices";
import { MUITextField } from "@abb/abb-arcadia-ui-react";

const PlatformAdministrator = () => {
  const appGeneralTextData = React.useContext(AppGeneralTextContext);
  const { general_text_data }: any = appGeneralTextData;
  const imagesData = React.useContext(ImagesContext);
  const { images }: any = imagesData;
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortEnabled, setSortEnabled] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [searchInput, setSearchInput] = useState<string>("");
  const [allPlatformAdmin, setPlatformAdmin] = useState<any>([]);
  const [permitted, setPermitted] = useState(true);

  useEffect(() => {
    callGetAllApplications();
    getPlatformAdmin();
  }, []);

  const callGetAllApplications = async () => {
    try {
      await getAllApplications();
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        setPermitted(false);
      }
    }
  };

  async function getPlatformAdmin() {
    try {
      const result = await getAllApplicationConfig(3);
      if (result) {
        const updatedData = [
          ...result,
          {
            applicationId: "Application provision",
            name: "Application Provisioning",
            description: "Setting up and configuring applications.",
            applicationSecurityId: null,
            applicationTypeId: 3,
            applicationTypeName: "Platform Administrator",
            adclient: null,
            embedUrl:
              "/platform_administration/manage_applications/Application_Provisioning",
            externalUrl: "",
            icon: "icon_applications_provision.svg",
            isActive: true,
            newTab: false,
            valuePillar: "Platform Administrator",
            tileOrder: 4,
          },
        ];
        setPlatformAdmin(updatedData);
        if (updatedData.length > 0) {
          const sortedApps = sortApps(updatedData);
          setPlatformAdmin(sortedApps);
        }
      }
    } catch (error) {}
  }
  const sortApps = (data: any) => {
    return data.sort((a: any, b: any) => {
      if (a.tileOrder === b.tileOrder) {
        return a.name.localeCompare(b.name);
      }
      return a.tileOrder - b.tileOrder;
    });
  };
  const handleSearchChange = (value: any) => {
    setSearchTerm(value);
    setSearchInput(value.length > SEARCH_TRIGGER_LENGTH ? value : "");
  };

  const toggleSortOrder = () => {
    setSortEnabled(true);
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };
  console.log(allPlatformAdmin);
  return (
    <div className="services-container">
      {permitted === false && (
        <div className="user-permission-message">
          You don't have the required permissions to access Platform
          Administration Application. Please contact administartor.
        </div>
      )}
      {permitted === true && (
        <div>
          <div className="intro-header">
            <h1>Platform Administration</h1>
          </div>
          <div className="intro-banner-container">
            <BannerImage />
          </div>
          <div className="intro-content">
            {general_text_data.description_home}
          </div>

          <div className="services-content">
            <div className="misc-actions-container mr-20">
              <div className="cta-btns">
                <Space>
                  <div className="input-group">
                    <MUITextField
                      onChange={handleSearchChange}
                      onKeyDown={() => {}}
                      placeholder={"Search for Application"}
                      size={32}
                    />
                  </div>
                  <div className="icon-sort" onClick={toggleSortOrder}>
                    {sortOrder === "asc" ? (
                      <img
                        className="icon-sort"
                        src={`${images.sort_icon_asc}`}
                        alt=""
                        title="Sort in descending order"
                      />
                    ) : (
                      <img
                        className="icon-sort"
                        src={`${images.sort_icon_dsc}`}
                        alt=""
                        title="Sort in ascending order"
                      />
                    )}
                  </div>
                </Space>
              </div>
            </div>
            <div className=" grid-container">
              {allPlatformAdmin
                .filter((app: any) =>
                  app.name.toLowerCase().includes(searchInput.toLowerCase())
                )
                .sort((a: any, b: any) => {
                  if (!sortEnabled) return 0;
                  const comparison = a.name.localeCompare(b.name);
                  return sortOrder === "asc" ? comparison : -comparison;
                })
                .map((data: any) => {
                  let url;
                  if (data.name === "Application Provisioning") {
                    url = "/platform_administration/manage_applications";
                  } else {
                    url = data.externalUrl || data.embedUrl;
                  }
                  const openInNewTab = data.newTab;
                  const imgSrc = `../assets/images/${data.icon}`;
                  return (
                    <div className="blocks-container col-3">
                      <Link
                        to={url}
                        reloadDocument={true}
                        target={openInNewTab ? "_blank" : "_self"}
                      >
                        <div className="blocks" key={data.id}>
                          <div className="app-info">
                            <div className="image-container">
                              <div>
                                <img src={imgSrc} alt={data.name} />
                              </div>
                            </div>
                            <div className="app-name">
                              <h4>{data.name}</h4>
                            </div>
                          </div>
                          <div className="box-content">
                            <p
                              title={
                                data?.description.length > 95
                                  ? data.description
                                  : undefined
                              }
                            >
                              {data?.description.length > 95
                                ? data.description.slice(0, 95) + " ..."
                                : data.description}
                            </p>
                          </div>

                          <div className="app-link">
                            {general_text_data.go_to_app}
                          </div>
                        </div>
                      </Link>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlatformAdministrator;
