// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-abb-container {
  padding: 20px;
  min-height: 80vh;
}
.contact-abb-container .contact-abb-cards {
  display: flex;
  justify-content: start;
  gap: 10px;
  padding: 25px 25px 25px 40px;
  border-radius: 8px;
  background: #ebebeb;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.contact-abb-container .contact-abb-cards .contact-abb-card-text {
  font-family: "ABBvoice-medium";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.contact-abb-container .mt-20 {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/contact_abb.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AAAE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,4BAAA;EACA,kBAAA;EACA,mBAAA;EACA,+CAAA;EACA,eAAA;AAEJ;AADI;EACE,8BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;AAGN;AAAE;EACE,gBAAA;AAEJ","sourcesContent":[".contact-abb-container {\n  padding: 20px;\n  min-height: 80vh;\n  .contact-abb-cards {\n    display: flex;\n    justify-content: start;\n    gap: 10px;\n    padding: 25px 25px 25px 40px;\n    border-radius: 8px;\n    background: #ebebeb;\n    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n    cursor: pointer;\n    .contact-abb-card-text {\n      font-family: \"ABBvoice-medium\";\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 500;\n    }\n  }\n  .mt-20 {\n    margin-top: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
